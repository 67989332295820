import { PublicClientApplication } from '@azure/msal-browser';

// For updating between Dev and Prod use isProd
const isProd = false;

const devCredentials = {
  clientId: 'ecd9a883-d5a5-4a36-b53e-1468d5f6ad7f',
  authority:
    'https://login.microsoftonline.com/42cc3295-cd0e-449c-b98e-5ce5b560c1d3',
};

const prodCredentials = {
  clientId: 'a2760efc-a573-4b54-8a8d-11272005c83f',
  authority:
    'https://login.microsoftonline.com/42cc3295-cd0e-449c-b98e-5ce5b560c1d3',
};

// Msal Configurations
const msalConfig = {
  auth: {
    clientId: isProd ? prodCredentials.clientId : devCredentials.clientId,
    authority: isProd ? prodCredentials.authority : devCredentials.authority,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
};

// Authentication Parameters
export const msalScopes = {
  scopes: ['https://graph.microsoft.com/.default'],
};

export const powerbiScopes = {
  scopes: [
    'https://analysis.windows.net/powerbi/api/Dataset.Read.All',
    'https://analysis.windows.net/powerbi/api/Dashboard.Read.All',
    'https://analysis.windows.net/powerbi/api/Group.Read',
    'https://analysis.windows.net/powerbi/api/Report.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/UserState.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Workspace.Read.All',
  ],
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const msalLogout = (): void => {
  try {
    localStorage.clear();
    msalInstance.logoutRedirect();
  } catch (e) {
    console.log(e);
  }
};
