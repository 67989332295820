import React from 'react';
import { Container } from 'react-bootstrap';
import DefaultIcon from '../../images/FinalOutcomeReportImages/Default View Icon.png';
import styles from './Util.module.css';

// Initial default screen in the Final Outcome Report asking user to make filter selections

const DefaultScreen: React.FC = () => {
  return (
    <Container>
      <Container className={styles.screen}>
        <img src={DefaultIcon} alt='Default Icon' className={styles.icons} />
        <h5>Please make the selections.</h5>
      </Container>
    </Container>
  );
};

export default DefaultScreen;
