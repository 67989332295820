import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { OUTCOME, USERROLECODE } from '../constants';
import { ReportSubmissionProps } from '../interfaces';
import ApproveRejectionModal from './ApproveRejectionModal';
import SaveModal from './SaveModal';

// Report Submission section has the buttons to Save, Accept and Reject a report
const ReportSubmission: React.FC<ReportSubmissionProps> = (
  props: ReportSubmissionProps
) => {
  // State variables to handle the display for Modal used for confirmation
  const [showSaveModal, setSaveModalDisplay] = useState<boolean>(false);
  const [showApproveRejectModal, setApproveRejectModal] = useState<boolean>(
    false
  );

  //State variable to hold the value of outcome in case of approval/rejection: Acceptance and Rejection
  const [outcome, setOutcome] = useState<string>(OUTCOME.APPROVE);

  //State variable to hold the value of To and CC email list which can be editted by the users before submission/rejection
  const [finalToList, setFinalToList] = useState<string>('');
  const [finalCcList, setFinalCcList] = useState<string>('');

  /*
  Description:  This function is called when the user clicks on Accept/Reject button. 
                The funciton first validates if all the required fields are filled and 
                only then displays the Modal where user can edit the mail list and add comments.
  Input: Action type i.e. Acceptance or rejection
  Sets the display of Approve/reject popup to true.
  */
  const submitAction = (actionType: string) => {
    const isDataValid = props.handleValidate(actionType);
    if (isDataValid === 1 && actionType === OUTCOME.SAVE) {
      setSaveModalDisplay(true);
    } else if (
      isDataValid === 1 &&
      (actionType === OUTCOME.APPROVE || actionType === OUTCOME.REJECT)
    ) {
      setApproveRejectModal(true);
    }
  };

  /*
  Description:  This function is called when the user confirms the submit(Acceptance/Rejection) action.
                props.handleSubmit function in the Final Outcome report component is called which makes 
                the required API calls to inser the data into backend  
  Input:  outcomeValue - Accept/Reject
          comment - Comment provided by the user for next role users
          toMailList and ccMailList - list of email Ids which is edittable  by users
  */
  const handleSubmit = (
    outcomeValue: string,
    comment: string,
    toMailList: string,
    ccMailList: string
  ) => {
    setApproveRejectModal(false);
    props.handleSubmit(outcomeValue, comment, toMailList, ccMailList);
  };

  /*
  Description:  This function is call when the user confirms save action in the popup
                props.handleSave function in the Final Outcome report component is called which makes 
                the required API calls to inser the data into backend 
  */
  const handleSave = () => {
    setSaveModalDisplay(false);
    props.handleSave();
  };

  return (
    <Container fluid className='ml-auto mt-3 mb-2'>
      {props.userRole <= USERROLECODE.APPROVER && (
        <Button
          variant='primary'
          onClick={() => submitAction(OUTCOME.SAVE)}
          disabled={!props.reportEditMode}
        >
          Save
        </Button>
      )}{' '}
      <Button
        variant='primary'
        onClick={() => {
          setFinalToList(props.emailList.approveToList);
          setFinalCcList(props.emailList.approveCcList);
          setOutcome(OUTCOME.APPROVE);
          submitAction(OUTCOME.APPROVE);
        }}
        disabled={!props.reportEditMode}
      >
        {props.userRole < USERROLECODE.GM_CFO ? 'Save & Submit' : 'Approve'}
      </Button>{' '}
      {props.userRole !== USERROLECODE.PREPARER && (
        <Button
          variant='outline-danger'
          onClick={() => {
            setFinalToList(props.emailList.rejectToList);
            setFinalCcList(props.emailList.rejectCcList);
            setOutcome(OUTCOME.REJECT);
            submitAction(OUTCOME.REJECT);
          }}
          disabled={!props.reportEditMode}
        >
          Reject
        </Button>
      )}{' '}
      <SaveModal
        showState={showSaveModal}
        setModal={() => setSaveModalDisplay(false)}
        saveUpdate={handleSave}
      />
      <ApproveRejectionModal
        showState={showApproveRejectModal}
        setModal={() => setApproveRejectModal(false)}
        submitOutcome={handleSubmit}
        outcome={outcome}
        toMailList={finalToList}
        ccMailList={finalCcList}
        setToMailList={setFinalToList}
        setCcMailList={setFinalCcList}
      />
    </Container>
  );
};

export default ReportSubmission;
