import React, { useEffect, useRef } from 'react';
import { InputGroup, FormControl, Container, Row, Col } from 'react-bootstrap';
import { ReportDetailsProps } from '../interfaces';
import styles from './ReportDetails.module.css';

// Report details section includes: Subject, Date of Review, Review Period, Attendees List and Report Summary
const ReportDetails: React.FC<ReportDetailsProps> = (
  props: ReportDetailsProps
) => {
  const styleForInput = { backgroundColor: '#FFFFFF' };

  //Text for subject line is constructed based on the market and current period. This text is uneditable.
  const subjectText =
    props.selectedFilter.unitCode +
    ' BSR Report ' +
    Math.floor(props.selectedFilter.yearQuarter / 100) +
    ' Q-' +
    (props.selectedFilter.yearQuarter % 100);

  // Defining reference variables  for Summary and Attendees input as the height of the input has to be increased based on content
  const summaryInput = useRef<HTMLTextAreaElement>(null);
  const attendeesInput = useRef<HTMLTextAreaElement>(null);

  // Setting initial height of the input field based on the content
  useEffect(() => {
    if (summaryInput.current) {
      summaryInput.current.style.height =
        summaryInput.current.scrollHeight + 'px';
    }
    if (attendeesInput.current) {
      attendeesInput.current.style.height =
        attendeesInput.current.scrollHeight + 'px';
    }
  }, []);

  /* 
  Description: This function updates the input field height based on the input text. It is called in the OnCHange event of the input field
  */
  const resizeTextBox = (elementRef: React.RefObject<HTMLTextAreaElement>) => {
    if (elementRef.current) {
      elementRef.current.style.height = elementRef.current.scrollHeight + 'px';
    }
  };
  return (
    <Container fluid={true} className='ml-auto mt-2'>
      <Row>
        <Col className='m-auto' sm>
          <label className={styles.Inputlables}>Subject</label>
          <FormControl
            aria-label='subject-line'
            value={subjectText}
            readOnly={true}
            style={styleForInput}
          />
          {props.reportEditMode && (
            <label className={styles.counterLabel}>
              {subjectText.length}/100
            </label>
          )}
        </Col>
        <Col className='m-auto' sm>
          <label className={styles.Inputlables}>Date of Review</label>
          <FormControl
            aria-label='review-date'
            value={props.reportDetails.dateOfReview}
            maxLength={100}
            readOnly={!props.reportEditMode}
            onChange={(event) => {
              const updatedDetails = Object.assign({}, props.reportDetails);
              updatedDetails.dateOfReview = event.target.value;
              props.setDetails(updatedDetails);
            }}
            className={
              props.reportDetails.dateOfReview.length === 0
                ? styles.invalidInput
                : ''
            }
            style={styleForInput}
          />
          {props.reportEditMode && (
            <label className={styles.counterLabel}>
              {props.reportDetails.dateOfReview.length}/100
            </label>
          )}
        </Col>
        <Col className='m-auto' sm>
          <label className={styles.Inputlables}>Review Period</label>
          <FormControl
            aria-label='review-period'
            value={props.reportDetails.reviewPeriod}
            maxLength={100}
            readOnly={!props.reportEditMode}
            onChange={(event) => {
              const updatedDetails = Object.assign({}, props.reportDetails);
              updatedDetails.reviewPeriod = event.target.value;
              props.setDetails(updatedDetails);
            }}
            className={
              props.reportDetails.reviewPeriod.length === 0
                ? styles.invalidInput
                : ''
            }
            style={styleForInput}
          />
          {props.reportEditMode && (
            <label className={styles.counterLabel}>
              {props.reportDetails.reviewPeriod.length}/100
            </label>
          )}
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col className='m-auto' sm>
          <label className={styles.Inputlables}>Attendees</label>
          <FormControl
            aria-label='review-attendees'
            as='textarea'
            rows={2}
            ref={attendeesInput}
            value={props.reportDetails.attendees}
            maxLength={500}
            readOnly={!props.reportEditMode}
            onChange={(event) => {
              const updatedDetails = Object.assign({}, props.reportDetails);
              updatedDetails.attendees = event.target.value;
              resizeTextBox(attendeesInput);
              props.setDetails(updatedDetails);
            }}
            className={
              props.reportDetails.attendees.length === 0
                ? styles.invalidInput + ' ' + styles.inputField
                : styles.inputField
            }
            style={styleForInput}
          />
          {props.reportEditMode && (
            <label className={styles.counterLabel}>
              {props.reportDetails.attendees.length}/500
            </label>
          )}
        </Col>
      </Row>
      <label className={styles.Inputlables + ' mt-2'}>Summary</label>
      <InputGroup size='sm'>
        <FormControl
          aria-label='summary'
          as='textarea'
          rows={10}
          ref={summaryInput}
          value={props.reportDetails.summary}
          onChange={(event) => {
            const updatedDetails = Object.assign({}, props.reportDetails);
            updatedDetails.summary = event.target.value;
            resizeTextBox(summaryInput);
            props.setDetails(updatedDetails);
          }}
          maxLength={3000}
          readOnly={!props.reportEditMode}
          className={
            props.reportDetails.summary.length === 0
              ? styles.invalidInput + ' ' + styles.inputField
              : styles.inputField
          }
          style={styleForInput}
        />
      </InputGroup>
      {props.reportEditMode && (
        <label className={styles.counterLabel}>
          {props.reportDetails.summary.length}/3000
        </label>
      )}
    </Container>
  );
};

export default ReportDetails;
