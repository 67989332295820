import { Container, Row, Col } from 'react-bootstrap';
import styles from './ProgressStepper.module.css';
import React from 'react';
import { CurrentRoleAndStatusProps } from '../interfaces';
import {
  getReportStatusString,
  getUserRoleString,
  REPORTSTATUSCODE,
} from '../constants';

// Indicates the state of Final Outcome Report for the selected filter values and the role of the user
const ProgressStepper: React.FC<CurrentRoleAndStatusProps> = (
  props: CurrentRoleAndStatusProps
) => {
  // Variables to check the current state
  const isNotPrepared =
    props.currentReportStatus === REPORTSTATUSCODE.NOTPREPARED;
  const isPrepared = props.currentReportStatus === REPORTSTATUSCODE.PREPARED;
  const isReviewed = props.currentReportStatus === REPORTSTATUSCODE.REVIEWED;
  const isApproved = props.currentReportStatus === REPORTSTATUSCODE.APPROVED;
  const isInterAccepted =
    props.currentReportStatus === REPORTSTATUSCODE.INTER_ACCEPTED;
  const isAccepted = props.currentReportStatus === REPORTSTATUSCODE.ACCEPTED;
  const isSubmitted = props.currentReportStatus === REPORTSTATUSCODE.SUBMITTED;
  const checkPrep = props.currentReportStatus < REPORTSTATUSCODE.PREPARED;
  const checkRev = props.currentReportStatus < REPORTSTATUSCODE.REVIEWED;
  const checkInter =
    props.currentReportStatus < REPORTSTATUSCODE.INTER_ACCEPTED;
  const checkApp = props.currentReportStatus < REPORTSTATUSCODE.APPROVED;
  const checkAcc = props.currentReportStatus < REPORTSTATUSCODE.ACCEPTED;
  const checkSub = props.currentReportStatus < REPORTSTATUSCODE.SUBMITTED;

  /*
  Every state is assigned a color (Refer the css file). 
  For a give state, all the proceeding state will have color of the current state and succeeding states will be inactive.
  */
  return (
    <Container fluid className='ml-auto mt-4 mb-2'>
      <Row>
        <Col className={styles.roleandStatusSection}>
          <b>Viewing as </b> {': '} {getUserRoleString(props.currentRole)}
        </Col>
      </Row>
      <Container className={styles.workflowStatus}>
        <Row>
          <Col className={styles.roleandStatusSection}>
            <b>Workflow Status </b> {': '}
            {getReportStatusString(props.currentReportStatus)}
          </Col>
        </Row>
      </Container>

      <Container className={styles.flowChart}>
        <Row>
          <Col>
            <ul className={styles.stepper}>
              <li
                className={
                  isNotPrepared
                    ? styles.active + ' ' + styles.notPrep
                    : isPrepared
                    ? styles.active + ' ' + styles.prep
                    : isReviewed
                    ? styles.active + ' ' + styles.reviewed
                    : isApproved
                    ? styles.active + ' ' + styles.approved
                    : isInterAccepted
                    ? styles.active + ' ' + styles.interAccepted
                    : isAccepted
                    ? styles.active + ' ' + styles.accepted
                    : isSubmitted
                    ? styles.active + ' ' + styles.submitted
                    : ''
                }
              >
                Not Prepared
              </li>
              <li
                className={
                  checkPrep
                    ? ''
                    : isPrepared
                    ? styles.active + ' ' + styles.prep
                    : isReviewed
                    ? styles.active + ' ' + styles.reviewed
                    : isApproved
                    ? styles.active + ' ' + styles.approved
                    : isInterAccepted
                    ? styles.active + ' ' + styles.interAccepted
                    : isAccepted
                    ? styles.active + ' ' + styles.accepted
                    : isSubmitted
                    ? styles.active + ' ' + styles.submitted
                    : ''
                }
              >
                Prepared
              </li>
              <li
                className={
                  checkRev
                    ? ''
                    : isNotPrepared
                    ? styles.active + ' ' + styles.notPrep
                    : isPrepared
                    ? styles.active + ' ' + styles.prep
                    : isReviewed
                    ? styles.active + ' ' + styles.reviewed
                    : isApproved
                    ? styles.active + ' ' + styles.approved
                    : isInterAccepted
                    ? styles.active + ' ' + styles.interAccepted
                    : isAccepted
                    ? styles.active + ' ' + styles.accepted
                    : isSubmitted
                    ? styles.active + ' ' + styles.submitted
                    : ''
                }
              >
                Reviewed
              </li>
              <li
                className={
                  checkApp
                    ? ''
                    : isNotPrepared
                    ? styles.active + ' ' + styles.notPrep
                    : isPrepared
                    ? styles.active + ' ' + styles.prep
                    : isReviewed
                    ? styles.active + ' ' + styles.reviewed
                    : isApproved
                    ? styles.active + ' ' + styles.approved
                    : isInterAccepted
                    ? styles.active + ' ' + styles.interAccepted
                    : isAccepted
                    ? styles.active + ' ' + styles.accepted
                    : isSubmitted
                    ? styles.active + ' ' + styles.submitted
                    : ''
                }
              >
                Approved
              </li>
              <li
                className={
                  checkInter
                    ? styles.partial
                    : isNotPrepared
                    ? styles.partial +
                      ' ' +
                      styles.partialActive +
                      ' ' +
                      styles.notPrep
                    : isPrepared
                    ? styles.partial +
                      ' ' +
                      styles.partialActive +
                      ' ' +
                      styles.prep
                    : isReviewed
                    ? styles.partial +
                      ' ' +
                      styles.partialActive +
                      ' ' +
                      styles.reviewed
                    : isApproved
                    ? styles.partial +
                      ' ' +
                      styles.partialActive +
                      ' ' +
                      styles.approved
                    : isInterAccepted
                    ? styles.partial +
                      ' ' +
                      styles.partialActive +
                      ' ' +
                      styles.interAccepted
                    : isAccepted
                    ? styles.partial +
                      ' ' +
                      styles.partialActive +
                      ' ' +
                      styles.accepted
                    : isSubmitted
                    ? styles.partial +
                      ' ' +
                      styles.partialActive +
                      ' ' +
                      styles.submitted
                    : styles.partial
                }
              >
                {isInterAccepted && 'In progress'}
              </li>
              <li
                className={
                  checkAcc
                    ? ''
                    : isNotPrepared
                    ? styles.active + ' ' + styles.notPrep
                    : isPrepared
                    ? styles.active + ' ' + styles.prep
                    : isReviewed
                    ? styles.active + ' ' + styles.reviewed
                    : isApproved
                    ? styles.active + ' ' + styles.approved
                    : isAccepted
                    ? styles.active + ' ' + styles.accepted
                    : isSubmitted
                    ? styles.active + ' ' + styles.submitted
                    : ''
                }
              >
                Accepted
              </li>
              <li
                className={
                  checkSub
                    ? ''
                    : isNotPrepared
                    ? styles.active + ' ' + styles.notPrep
                    : isPrepared
                    ? styles.active + ' ' + styles.prep
                    : isReviewed
                    ? styles.active + ' ' + styles.reviewed
                    : isApproved
                    ? styles.active + ' ' + styles.approved
                    : isAccepted
                    ? styles.active + ' ' + styles.accepted
                    : isSubmitted
                    ? styles.active + ' ' + styles.submitted
                    : ''
                }
              >
                Submitted
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ProgressStepper;
