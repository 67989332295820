import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { SHARE_POINT_LINKS } from '../../config/powerBiReportDetails';
import { PowerBINavBarProps } from '../interfaces';
import { REPORT_TYPES, REPORT_PAGE_MAPPING } from './PowerBIConstants';
import styles from './ReportNavigationTab.module.css';
import ExcelIcon from '../../images/PowerBIEmbed/excel.png';
import { Link, useLocation } from 'react-router-dom';

// Navigation tab to switch between different Power BI report
const ReportNavigationTab: React.FC<PowerBINavBarProps> = (
  props: PowerBINavBarProps
) => {
  const location = useLocation();
  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      className={styles.navigationArea + ' p-0 pr-1'}
    >
      <Navbar.Toggle className='ml-auto' />

      <Navbar.Collapse>
        <Nav className={styles.reportLinks}>
          <NavDropdown
            title='Heatmap Homepage'
            id='nav-dropdown-Heatmap'
            className={
              location.pathname.includes(`/${REPORT_TYPES.HEATMAP}/`) ||
              location.pathname.endsWith(`/Report`) ||
              location.pathname.endsWith(`/Report/`)
                ? styles.activeTab + ' ml-3'
                : 'ml-3'
            }
          >
            {REPORT_PAGE_MAPPING.filter(
              (item) => item.type === REPORT_TYPES.HEATMAP
            ).map((item) => (
              <NavDropdown.Item
                key={item.id}
                eventKey={item.id.toString()}
                to={`${item.type}/${item.pageDisplayName}`}
                as={Link}
              >
                {item.pageDisplayName}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
          <NavDropdown
            title='Assets'
            id='nav-dropdown-Assets'
            className={
              location.pathname.includes(`/${REPORT_TYPES.ASSETS}/`)
                ? styles.activeTab + ' ml-3'
                : 'ml-3'
            }
          >
            {REPORT_PAGE_MAPPING.filter(
              (item) => item.type === REPORT_TYPES.ASSETS
            ).map((item) => (
              <NavDropdown.Item
                key={item.id}
                eventKey={item.id.toString()}
                to={`${item.type}/${item.pageDisplayName}`}
                as={Link}
              >
                {item.pageDisplayName}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
          <NavDropdown
            title='Liabilities and Equity'
            id='nav-dropdown-Liab'
            className={
              location.pathname.includes(`/${REPORT_TYPES.LIABILITIES}/`)
                ? styles.activeTab + ' ml-3'
                : 'ml-3'
            }
          >
            {REPORT_PAGE_MAPPING.filter(
              (item) => item.type === REPORT_TYPES.LIABILITIES
            ).map((item) => (
              <NavDropdown.Item
                key={item.id}
                eventKey={item.id.toString()}
                to={`${item.type}/${item.pageDisplayName}`}
                as={Link}
              >
                {item.pageDisplayName}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
          {props.displayHelperSchedules && (
            <NavDropdown
              title='Helper Schedule'
              id='nav-dropdown-Liab'
              className={
                location.pathname.includes(`/${REPORT_TYPES.HELPER_SCHEDULE}/`)
                  ? styles.activeTab + ' ml-3'
                  : 'ml-3'
              }
            >
              {REPORT_PAGE_MAPPING.filter(
                (item) => item.type === REPORT_TYPES.HELPER_SCHEDULE
              ).map((item) => (
                <NavDropdown.Item
                  key={item.id}
                  eventKey={item.id.toString()}
                  to={`${item.type}/${item.pageDisplayName}`}
                  as={Link}
                >
                  {item.pageDisplayName}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          )}
        </Nav>
        <Nav activeKey='excelLink'>
          <Nav.Link
            eventKey='excelLink'
            href={SHARE_POINT_LINKS.excelTemplateSharepointLocation}
            target='_blank'
            className={styles.excelOption}
          >
            <img src={ExcelIcon} className='mr-auto' alt='Excel Template' />{' '}
            Excel Export
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default ReportNavigationTab;
