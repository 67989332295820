import {
  POWER_BI_PAGE_DETAILS,
  REPORT_SPLIT_DETAILS,
} from '../../config/powerBiReportDetails';
import { EmbeddedReportSlicerValues, PowerBIEmbedDetails } from '../interfaces';

export const REPORT_TYPES = {
  HEATMAP: 0,
  ASSETS: 1,
  LIABILITIES: 2,
  HELPER_SCHEDULE: 3,
};

export const INITIAL_SLICER_VALUES: EmbeddedReportSlicerValues = {
  currentPeriod: [''],
  currentPeriodOperator: 'In',
  comparisonPeriod: [''],
  comparisonPeriodOperator: 'In',
  sectorName: [''],
  sectorNameOperator: 'In',
  buName: [''],
  buNameOperator: 'In',
  mktUnitName: [''],
  mktUnitNameOperator: 'In',
  company: [''],
  companyOperator: 'In',
  currencyValue: [''],
  currencyValueOperator: 'In',
  currencyUnit: [''],
  currencyUnitOperator: 'In',
  entity: [''],
  entityOperator: 'In',
  profitCenter: [''],
  profitCenterOperator: 'In',
  reportingLevel: [''],
  reportingLevelOperator: 'In',
};

export const POWER_BI_VISUAL_TYPE = {
  SLICER: 'slicer',
};

export const SLICER_TABLES_COLUMNS = {
  CURRENT_PERIOD_TABLE: 'Current Period Table',
  CURRENT_PERIOD_COLUMN: 'Current Period',
  COMPARISON_PERIOD_TABLE: 'Comparison Period Table',
  COMPARISON_PERIOD_COLUMN: 'Comparison Period',
  BSR_SECTOR_TABLE: 'BSR Sector Mapping',
  SECTOR_NAME_COLUMN: 'Sector Name',
  BU_NAME_COLUMN: 'BU Name',
  MARKET_COLUMN: 'Market Unit Name',
  COMPANY_COLUMN: 'Company',
  CURRENCY_VALUE_TABLE: 'Currency Values',
  CURRENCY_VALUE_COLUMN: 'Currency',
  CURRENCY_UNIT_TABLE: 'Currency Units',
  CURRENCY_UNIT_COLUMN: 'Units',
  ENTITY_TABLE: 'Entity Mapping',
  ENTITY_COLUMN: 'Entity Name',
  PROFIT_CENTER_TABLE: 'Profit Center Mapping',
  PROFIT_CENTER_COLUMN: 'Profit Center',
  REPORTING_LEVEL_TABLE: 'Reporting Level',
  REPORTING_LEVEL_COLUMN: 'Reporting Level Desription',
};

export const POWER_BI_SLICER_CONSTANTS = {
  CURRENCY_VALUE_USD: 'USD',
  CURRENCY_UNIT_MILLIONS: 'Millions',
  CURRENCY_UNIT_THOUSANDS: 'Thousands',
};

export const VALIDATION_SCREEN_PAGE_NAME = 'Validation Dashboard';

export const REPORT_PAGE_MAPPING: PowerBIEmbedDetails[] = [
  {
    id: 1,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.heatmapReportId,
    pageId: POWER_BI_PAGE_DETAILS.heatmap,
    pageDisplayName: 'Heatmap Homepage',
    type: REPORT_TYPES.HEATMAP,
  },
  {
    id: 2,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.assetsReportId,
    pageId: POWER_BI_PAGE_DETAILS.ccst,
    pageDisplayName: 'Cash And Short Term Inv.',
    type: REPORT_TYPES.ASSETS,
  },
  {
    id: 3,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.assetsReportId,
    pageId: POWER_BI_PAGE_DETAILS.ar,
    pageDisplayName: 'Net Trade Receivable',
    type: REPORT_TYPES.ASSETS,
  },
  {
    id: 4,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.assetsReportId,
    pageId: POWER_BI_PAGE_DETAILS.noar,
    pageDisplayName: 'Net Other Notes Rec & AR Other',
    type: REPORT_TYPES.ASSETS,
  },
  {
    id: 5,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.assetsReportId,
    pageId: POWER_BI_PAGE_DETAILS.inventory,
    pageDisplayName: 'Net Inventories',
    type: REPORT_TYPES.ASSETS,
  },
  {
    id: 6,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.assetsReportId,
    pageId: POWER_BI_PAGE_DETAILS.prepaid,
    pageDisplayName: 'Prepaid Expenses & Other',
    type: REPORT_TYPES.ASSETS,
  },
  {
    id: 7,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.assetsReportId,
    pageId: POWER_BI_PAGE_DETAILS.icar,
    pageDisplayName: 'Intercompany Receivables',
    type: REPORT_TYPES.ASSETS,
  },
  {
    id: 8,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.assetsReportId,
    pageId: POWER_BI_PAGE_DETAILS.capex,
    pageDisplayName: 'Net Fixed Assets (Include CIP)',
    type: REPORT_TYPES.ASSETS,
  },
  {
    id: 9,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.assetsReportId,
    pageId: POWER_BI_PAGE_DETAILS.rou,
    pageDisplayName: 'ROU Asset Operating Lease',
    type: REPORT_TYPES.ASSETS,
  },
  {
    id: 10,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.assetsReportId,
    pageId: POWER_BI_PAGE_DETAILS.goodwill_otherAssets,
    pageDisplayName: 'Goodwill & Other Intangibles',
    type: REPORT_TYPES.ASSETS,
  },
  {
    id: 11,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.assetsReportId,
    pageId: POWER_BI_PAGE_DETAILS.goodwill_otherAssets,
    pageDisplayName: 'Other Assets (Deposit)',
    type: REPORT_TYPES.ASSETS,
  },
  {
    id: 12,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.assetsReportId,
    pageId: POWER_BI_PAGE_DETAILS.dta,
    pageDisplayName: 'Deferred Tax Assets',
    type: REPORT_TYPES.ASSETS,
  },
  {
    id: 13,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.currentDebt_leaseSt_leaseLt,
    pageDisplayName: 'Current L/T Debt',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 14,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.currentDebt_leaseSt_leaseLt,
    pageDisplayName: 'Lease Liability (ST)',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 15,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.ap,
    pageDisplayName: 'Accounts Payable',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 16,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.accruedTax,
    pageDisplayName: 'Accrued Taxes (inc & other)',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 17,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.oal,
    pageDisplayName: 'Other Accrued Liabilities',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 18,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.icap,
    pageDisplayName: 'Intercompany Payables',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 19,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.currentDebt_leaseSt_leaseLt,
    pageDisplayName: 'Lease Liability (LT)',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 20,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.oltl,
    pageDisplayName: 'Other Long Term Liabilities',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 21,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.dtl,
    pageDisplayName: 'Deferred Income Taxes (DTL)',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 22,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.capitalStock_re_nci_cta,
    pageDisplayName: 'Capital Stock & Pd in Capital',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 23,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.capitalStock_re_nci_cta,
    pageDisplayName: 'Retained Earnings',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 24,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.capitalStock_re_nci_cta,
    pageDisplayName: 'Cumulative Translation Adj.',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 25,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.liabilitiesReportId,
    pageId: POWER_BI_PAGE_DETAILS.capitalStock_re_nci_cta,
    pageDisplayName: 'Non Controlling Interest',
    type: REPORT_TYPES.LIABILITIES,
  },
  {
    id: 26,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.heatmapReportId,
    pageId: POWER_BI_PAGE_DETAILS.summaryBS,
    pageDisplayName: 'Summary BS',
    type: REPORT_TYPES.HEATMAP,
  },
  {
    id: 27,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.heatmapReportId,
    pageId: POWER_BI_PAGE_DETAILS.cccWatchouts,
    pageDisplayName: 'CCC Watchouts',
    type: REPORT_TYPES.HEATMAP,
  },
  {
    id: 28,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.heatmapReportId,
    pageId: POWER_BI_PAGE_DETAILS.cashflow,
    pageDisplayName: 'Cashflow',
    type: REPORT_TYPES.HEATMAP,
  },
  {
    id: 29,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.heatmapReportId,
    pageId: POWER_BI_PAGE_DETAILS.gcsSummary,
    pageDisplayName: 'GCS Summary',
    type: REPORT_TYPES.HEATMAP,
  },
  {
    id: 30,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.heatmapReportId,
    pageId: POWER_BI_PAGE_DETAILS.hfmSapRecon,
    pageDisplayName: 'Recon. SAP-HFM',
    type: REPORT_TYPES.HEATMAP,
  },
  {
    id: 31,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.heatmapReportId,
    pageId: POWER_BI_PAGE_DETAILS.blacklineSummary,
    pageDisplayName: 'Blackline Summary',
    type: REPORT_TYPES.HEATMAP,
  },
  {
    id: 32,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.helperReportId,
    pageId: POWER_BI_PAGE_DETAILS.validationScreen,
    pageDisplayName: VALIDATION_SCREEN_PAGE_NAME,
    type: REPORT_TYPES.HELPER_SCHEDULE,
  },
  {
    id: 33,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.helperReportId,
    pageId: POWER_BI_PAGE_DETAILS.pendingUload,
    pageDisplayName: 'Pending ULOAD',
    type: REPORT_TYPES.HELPER_SCHEDULE,
  },
  {
    id: 34,
    groupId: REPORT_SPLIT_DETAILS.groupId,
    reportId: REPORT_SPLIT_DETAILS.helperReportId,
    pageId: POWER_BI_PAGE_DETAILS.existingUload,
    pageDisplayName: 'Existing ULOAD',
    type: REPORT_TYPES.HELPER_SCHEDULE,
  },
];

export const INITIAL_EMBED_DETAILS: PowerBIEmbedDetails =
  REPORT_PAGE_MAPPING[0];
