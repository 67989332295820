import React from 'react';
import { Alert } from 'react-bootstrap';
import { ErrorContianerProps } from '../interfaces';

/*
 Default screen to display error during login or due to access issues.
 Error message is passed in the props.
*/
const ErrorContainer: React.FC<ErrorContianerProps> = (
  props: ErrorContianerProps
) => {
  return (
    <Alert variant='danger'>
      <Alert.Heading>You got an error!</Alert.Heading>
      <p>{props.message}</p>
    </Alert>
  );
};

export default ErrorContainer;
