import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import DeleteConfirmation from './DeleteConfirmation';
import {
  ReportLineItemContainerProps,
  ReportLineItemObject,
  DeleteObjectProperties,
} from '../interfaces';
import styles from './ReportLineItemContainer.module.css';
import ReportLineItemRow from '../ReportLineItemRow/ReportLineItemRow';
import AddIcon from '../../images/ReportLineItems/addIcon.png';
import { getReportingLevelCode } from '../constants';

// Grid for displaying line items
// Displayed in case user has edit access
const ReportLineItemContainer: React.FC<ReportLineItemContainerProps> = (
  props: ReportLineItemContainerProps
) => {
  // maxId stores maximum id among all report line items
  let maxId = 1;
  if (props.lineItems.length !== 0) {
    maxId = props.lineItems.reduce((previous, current) =>
      previous.id > current.id ? previous : current
    ).id;
  }
  const [showDeleteModal, setModalDisplay] = useState<boolean>(false);
  const [curRow, setCurRow] = useState<DeleteObjectProperties>();
  const [defaultItemId, setDefaultItemId] = useState(maxId + 1);

  /* 
    Description: Function to update existing line item
    Input: Line Item to be updated
  */
  const updateLineItem = (newLineItem: ReportLineItemObject) => {
    const newSample = props.lineItems.filter(
      (item) => item.id !== newLineItem.id
    );
    const finalSample = [...newSample, newLineItem].sort(
      (item1, item2) => item1.id - item2.id
    );
    props.setLineItems(finalSample);
  };

  /* 
    Description: Function to delete line item
    Input: taken from curRow state variable
    In case existing item is deleted, it is added to deletedLineItemList
    Delete popup is disabled after performing delete
  */
  const deleteAfterConfirmation = () => {
    const newSample = props.lineItems.filter((item) => item.id !== curRow?.id);
    props.setLineItems(newSample);
    if (curRow?.isExisting === 1) {
      props.setDeletedLineItemList([...props.deletedLineItemList, curRow.id]);
    }
    setModalDisplay(false);
  };

  /* 
    Description: Function to open confirmation popup to delete line item
    Input: lineItemId, isExistingRecord
    Delete popup is enabled
  */
  const deleteLineItem = (lineItemId: number, isExistingRecord: number) => {
    setCurRow({
      id: lineItemId,
      isExisting: isExistingRecord,
    });
    setModalDisplay(true);
  };

  /* 
    Description: Function to add new line item
  */
  const addNewLineItem = () => {
    const newLineItem: ReportLineItemObject = {
      id: defaultItemId,
      reviewerPeriod: '',
      schedule: '',
      bsrObservation: '',
      financialImpact: '',
      status: false,
      comments: '',
      isCarriedForward: false,
      reportingLevel: getReportingLevelCode(
        props.selectedFilter.reportingLevel
      ),
      unitCode: props.selectedFilter.unitCode,
      yearQuarter: props.selectedFilter.yearQuarter,
      isExistingRecord: 0,
      isUpdated: 1,
    };
    props.setLineItems([...props.lineItems, newLineItem]);
    setDefaultItemId(defaultItemId + 1);
  };

  return (
    <Container fluid={true} className='ml-auto mt-2'>
      {/*Mobile View for adding line items*/}
      <section className={styles.lineItemAddSection}>
        <Row>
          <Col style={{ font: 'normal normal bold 1rem Segoe UI' }}>
            Line Items
          </Col>
          {props.reportEditMode && (
            <Col className={styles.buttonSection}>
              <Button
                title={'Add an item'}
                variant='primary'
                onClick={addNewLineItem}
                className='ml-1'
              >
                <img className='mb-1' src={AddIcon} alt='add items' />
              </Button>
            </Col>
          )}
        </Row>
      </section>
      {/*Desktop and Table view for line item header and adding items*/}
      <section className={styles.lineItemHeaderSection}>
        <Row>
          <Col
            lg={1}
            md={1}
            sm={1}
            style={{ font: 'normal normal bold 1rem Segoe UI' }}
          >
            Reviewer Period
          </Col>
          <Col
            lg={2}
            md={2}
            sm={2}
            style={{ font: 'normal normal bold 1rem Segoe UI' }}
          >
            Schedule
          </Col>
          <Col
            lg={3}
            md={2}
            sm={2}
            style={{ font: 'normal normal bold 1rem Segoe UI' }}
          >
            BSR Observation
          </Col>
          <Col
            lg={1}
            md={2}
            sm={2}
            style={{ font: 'normal normal bold 1rem Segoe UI' }}
          >
            Financial Impact
            <br />
            (in USD)
          </Col>
          <Col
            lg={1}
            md={2}
            sm={2}
            style={{ font: 'normal normal bold 1rem Segoe UI' }}
          >
            Status
          </Col>
          <Col
            lg={3}
            md={2}
            sm={2}
            style={{ font: 'normal normal bold 1rem Segoe UI' }}
          >
            Comments
          </Col>
          {props.reportEditMode && (
            <Col className={styles.buttonSection} lg={1} md={1} sm={1}>
              <Button
                title={'Add an item'}
                variant='primary'
                onClick={addNewLineItem}
                className='ml-1'
              >
                <img className='mb-1' src={AddIcon} alt='add items' />
              </Button>
            </Col>
          )}
        </Row>
      </section>
      {/* Display message in case there are no line items */}
      {props.lineItems.length === 0 && (
        <label className={styles.alertDisplay}>
          No items to display. Please click on + button to add line items.
        </label>
      )}
      <hr></hr>
      {props.lineItems.map((item) => {
        return (
          <div key={item.id}>
            <ReportLineItemRow
              data={item}
              deleteItem={deleteLineItem}
              updateItem={updateLineItem}
              reportEditMode={props.reportEditMode}
              unsavedItemCounter={props.unsavedItemCounter}
              setUnsavedItemCounter={props.setUnsavedItemCounter}
              key={item.id}
            />
          </div>
        );
      })}
      {curRow && (
        <DeleteConfirmation
          showState={showDeleteModal}
          setModal={() => setModalDisplay(false)}
          confirmDelete={deleteAfterConfirmation}
        />
      )}
    </Container>
  );
};

export default ReportLineItemContainer;
