import { msalInstance, powerbiScopes } from '../config/msalConfig';
import { TokenDetails } from './interfaces';

export const getPowerBiAccessToken = async (): Promise<TokenDetails> => {
  let response;
  const loginRequest = {
    scopes: powerbiScopes.scopes,
  };
  try {
    response = await msalInstance.acquireTokenSilent({
      account: msalInstance.getAllAccounts()[0],
      scopes: powerbiScopes.scopes,
    });
  } catch (err) {
    response = await msalInstance.loginPopup(loginRequest);
  }
  return { accessToken: response.accessToken, expiry: response.expiresOn };
};
