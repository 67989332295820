import axios from 'axios';
import {
  ActivityLogItem,
  EmailNotificationObject,
  EmailRecipientAndGmCfoDcCountObject,
  ReportCommentObject,
  ReportDetailsObject,
  ReportLineItemObject,
  SubmissionObjectForApprovers,
  UserDetailsObject,
} from './interfaces';
import { USERROLESTRING } from './constants';

export const getLoggedInUserDetails = async (
  username: string
): Promise<UserDetailsObject[]> => {
  const response = await axios({
    url: '/Workflow/GetUserDetails',
    method: 'POST',
    timeout: 0,
    params: {
      emailId: username,
    },
  });
  return response.data;
};

export const getReportDetails = async (
  yearQuarter: number,
  reportingLevel: number,
  unitCode: string
): Promise<ReportDetailsObject> => {
  const response = await axios({
    url: '/Workflow/GetReportStatus',
    method: 'POST',
    timeout: 0,
    params: {
      yearQuarter: yearQuarter,
      reportingLevel: reportingLevel,
      unitCode: unitCode,
    },
  });
  return response.data[0];
};

export const getReportLineItems = async (
  yearQuarter: number,
  unitCode: string
): Promise<ReportLineItemObject[]> => {
  const response = await axios({
    url: `/Workflow/GetReportLineItems`,
    method: 'POST',
    timeout: 0,
    params: {
      yearQuarter: yearQuarter,
      unitCode: unitCode,
    },
  });
  return response.data;
};

export const getActivityLogDetails = async (
  yearQuarter: number,
  unitCode: string
): Promise<ActivityLogItem[]> => {
  const response = await axios({
    url: `/Workflow/GetActivityLogs`,
    method: 'POST',
    timeout: 0,
    params: {
      yearQuarter: yearQuarter,
      unitCode: unitCode,
    },
  });
  return response.data;
};

export const getReportComments = async (
  yearQuarter: number,
  unitCode: string,
  commentRecipient: string
): Promise<ReportCommentObject[]> => {
  let userRole1 = '';
  let userRole2 = '';
  switch (commentRecipient) {
    case USERROLESTRING.PREPARER:
      userRole1 = USERROLESTRING.REVIEWER;
      break;
    case USERROLESTRING.REVIEWER:
      userRole1 = USERROLESTRING.PREPARER;
      userRole2 = USERROLESTRING.APPROVER;
      break;
    case USERROLESTRING.APPROVER:
      userRole1 = USERROLESTRING.REVIEWER;
      userRole2 = USERROLESTRING.CONTROLLER;
      break;
    default:
      userRole1 = '';
      userRole2 = '';
  }
  const response = await axios({
    url: `/Workflow/GetComments`,
    method: 'POST',
    timeout: 0,
    params: {
      yearQuarter: yearQuarter,
      unitCode: unitCode,
      commentRecipient: commentRecipient,
      userRole1: userRole1,
      userRole2: userRole2,
    },
  });
  return response.data;
};

export const InsertOrUpdateReportLineItems = async (
  reportLineItems: ReportLineItemObject[]
): Promise<void> => {
  await axios({
    url: '/Workflow/InsertOrUpdateLineItems',
    method: 'POST',
    timeout: 0,
    data: reportLineItems,
    headers: {
      'content-type': 'application/json; utf-8',
    },
  });
};

export const DeleteLineItems = async (itemIdList: number[]): Promise<void> => {
  await axios({
    url: '/Workflow/DeleteLineItems',
    method: 'DELETE',
    timeout: 0,
    params: { lineItemIds: itemIdList.join() },
  });
};

export const insertActivityLog = async (
  activityLog: ActivityLogItem
): Promise<void> => {
  await axios({
    url: `/Workflow/InsertActivityLog`,
    method: 'POST',
    timeout: 0,
    data: activityLog,
    headers: {
      'content-type': 'application/json; utf-8',
    },
  });
};

export const updateReportDetails = async (
  reportDetail: ReportDetailsObject
): Promise<void> => {
  await axios({
    url: `/Workflow/UpdateReportStatus`,
    method: 'POST',
    timeout: 0,
    data: reportDetail,
    headers: {
      'content-type': 'application/json; utf-8',
    },
  });
};

export const submissionForApprovers = async (
  details: SubmissionObjectForApprovers
): Promise<number> => {
  const response = await axios({
    url: `/Workflow/SubmissionAction`,
    method: 'POST',
    timeout: 0,
    data: details,
    headers: {
      'content-type': 'application/json; utf-8',
    },
  });
  return response.data;
};

export const getAcceptanceFlagForGmCfo = async (
  yearQuarter: number,
  unitCode: string,
  userEmail: string
): Promise<0 | 1> => {
  const response = await axios({
    url: `/Workflow/CheckAcceptanceState`,
    method: 'POST',
    timeout: 0,
    params: {
      yearQuarter: yearQuarter,
      unitCode: unitCode,
      emailId: userEmail,
    },
  });
  return response.data;
};

export const getMailListAndGmCfoCount = async (
  reportingLevel: number,
  unitCode: string,
  userRole: string,
  finalSubmissionLevel: number
): Promise<EmailRecipientAndGmCfoDcCountObject> => {
  const response = await axios({
    url: `/Workflow/GetMailListAndGmCfoCount`,
    method: 'POST',
    timeout: 0,
    params: {
      reportingLevel: reportingLevel,
      unitCode: unitCode,
      userRole: userRole,
      finalSubmissionLevel: finalSubmissionLevel,
    },
  });
  return response.data;
};

export const sendMailToUsers = async (
  emailObject: EmailNotificationObject
): Promise<void> => {
  await axios({
    url: `/Workflow/SendMail`,
    method: 'POST',
    timeout: 0,
    data: emailObject,
    headers: {
      'content-type': 'application/json; utf-8',
    },
  });
};
