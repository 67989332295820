import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { OUTCOME } from '../constants';
import { ApproveRejectProps } from '../interfaces';
import styles from './ReportSubmission.module.css';

// Popup window for accepatance/rejection confirmation
const ApproveRejectionModal: React.FC<ApproveRejectProps> = (
  props: ApproveRejectProps
) => {
  // State variable to store the comment entered by user
  const [comment, setComment] = useState<string>('');
  /*
  Comments are mandatory when the report is rejected.
  The alert message is displayed when the user tries to confrim rejection without comments.
  This state variable handles the display of the alert message.
  */
  const [alertMessage, setAlertDisplay] = useState<boolean>(false);

  return (
    <Modal
      size='lg'
      onHide={() => {
        setAlertDisplay(false);
        props.setModal();
      }}
      show={props.showState}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.outcome} the Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to {props.outcome.toLowerCase()} the report?
        </p>
        <Form.Label>
          Comments
          {alertMessage && (
            <p className={styles.rejectMessage}>Please provide comments.</p>
          )}
        </Form.Label>
        <Form.Control
          as='textarea'
          rows={3}
          onChange={(event) => setComment(event.target.value)}
        />
        <Form.Label>To:</Form.Label>
        <Form.Control
          as='textarea'
          rows={3}
          value={props.toMailList}
          onChange={(event) => props.setToMailList(event.target.value)}
        />
        <Form.Label>CC:</Form.Label>
        <Form.Control
          as='textarea'
          rows={3}
          value={props.ccMailList}
          onChange={(event) => props.setCcMailList(event.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (props.outcome === OUTCOME.REJECT && comment === '') {
              setAlertDisplay(true);
            } else {
              props.submitOutcome(
                props.outcome,
                comment,
                props.toMailList,
                props.ccMailList
              );
            }
          }}
          variant='success'
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            setAlertDisplay(false);
            props.setModal();
          }}
          variant='danger'
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveRejectionModal;
