import { useEffect } from 'react';
import { msalInstance, msalScopes } from '../../config/msalConfig';
import { HandleLoginProps } from '../interfaces';

/* 
  Description: Function to save Access token, email id and username to session storage
  Input: Access token, Email id, Username
*/
const saveToSessionStorage = (
  msalAccessToken: string,
  userEmail: string,
  userName = ''
) => {
  sessionStorage.setItem('msalAccessToken', msalAccessToken);
  userName && sessionStorage.setItem('userName', userName);
  userEmail && sessionStorage.setItem('userEmail', userEmail);
};

const HandleLogin: React.FC<HandleLoginProps> = (props: HandleLoginProps) => {
  useEffect(() => {
    const login = () => {
      msalInstance.handleRedirectPromise().then((response) => {
        const tokenResponse = response;
        let accountObj;
        const loginRequest = {
          scopes: msalScopes.scopes,
        };
        if (tokenResponse) {
          accountObj = tokenResponse.account;
        } else {
          accountObj = msalInstance.getAllAccounts()[0];
        }
        if (accountObj && tokenResponse) {
          saveToSessionStorage(
            tokenResponse.accessToken,
            msalInstance.getAllAccounts()[0].username,
            msalInstance.getAllAccounts()[0].name
          );
          props.setAuthenticationState('authenticated');
        } else if (accountObj) {
          msalInstance
            .acquireTokenSilent({
              account: msalInstance.getAllAccounts()[0],
              scopes: msalScopes.scopes,
            })
            .then((data) => {
              saveToSessionStorage(
                data.accessToken,
                msalInstance.getAllAccounts()[0].username,
                msalInstance.getAllAccounts()[0].name
              );
              props.setAuthenticationState('authenticated');
            })
            .catch(() => {
              props.setAuthenticationState('uauthenticated');
            });
        } else {
          msalInstance
            .loginRedirect(loginRequest)
            .then(() => {
              // saveToSessionStorage(
              //   data.accessToken,
              //   msalInstance.getAllAccounts()[0].username,
              //   msalInstance.getAllAccounts()[0].name
              // );
              props.setAuthenticationState('authenticated');
            })
            .catch(() => {
              props.setAuthenticationState('unauthenticated');
            });
        }
      });
    };
    login();
  });

  return null;
};

export default HandleLogin;
