import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './FinalSubmissionLevel.module.css';
import { FinalSubmissionProps } from '../interfaces';
import { FINALSUBMISSIONSTRING } from '../constants';

// Dropdown menu to select final submission level
// Editable by: Preparers, Reviewers, Controllers
// Viewable by: All
const FinalSubmissionLevelInput: React.FC<FinalSubmissionProps> = (
  props: FinalSubmissionProps
) => {
  const styleForInput = { backgroundColor: '#FFFFFF' };
  const getAlertForGmCfoDc = (
    gmcfoNotPresent: boolean,
    dcNotPresent: boolean
  ): string => {
    const base = '* Few options are disabled as there are no ';
    const ending =
      gmcfoNotPresent && dcNotPresent
        ? 'GM/CFO(s) and Designated Controllers'
        : gmcfoNotPresent
        ? 'GM/CFO(s)'
        : dcNotPresent
        ? 'Designated Controllers'
        : '';
    return base + ending;
  };
  return (
    <Container fluid={true} className='ml-auto mt-2'>
      <Row>
        <Col lg={3} md={3} sm={3}>
          <label className={styles.inputLabels}>Final Submission Level</label>
          {/* If no users are present in GM&CFO role, then display message */}
          {(props.gmCFONotPresent || props.dcNotPresent) &&
            props.reportEditMode && (
              <p className={styles.alertLabels}>
                {getAlertForGmCfoDc(props.gmCFONotPresent, props.dcNotPresent)}
              </p>
            )}
          <select
            value={props.reportDetails.finalSubmissionLevel}
            className='form-control'
            onChange={(event) => {
              const updatedDetails = Object.assign({}, props.reportDetails);
              updatedDetails.finalSubmissionLevel = Number(event.target.value);
              props.setDetails(updatedDetails);
            }}
            disabled={!props.reportEditMode}
            style={styleForInput}
          >
            <option value='1'>{FINALSUBMISSIONSTRING.CONTROLLER}</option>

            {/* If no users are present in GM&CFO role, then hide GM&CFO options*/}
            {(!props.reportEditMode || !props.gmCFONotPresent) && (
              <option value='2'>{FINALSUBMISSIONSTRING.GM_CFO}</option>
            )}
            {/* If no users are present in Designated Controller role, then hide Designated Controller option*/}
            {(!props.reportEditMode || !props.dcNotPresent) && (
              <option value='3'>
                {FINALSUBMISSIONSTRING.DESIGNATED_CONTROLLER}
              </option>
            )}
            {/* If no users are present in GM&CFO and Designated Controller role, then hide GM&CFO and Designated Controller option*/}
            {(!props.reportEditMode ||
              (!props.gmCFONotPresent && !props.dcNotPresent)) && (
              <option value='4'>
                {FINALSUBMISSIONSTRING.GM_CFO_DESIGNATED_CONTROLLER}
              </option>
            )}
          </select>
        </Col>
      </Row>
    </Container>
  );
};

export default FinalSubmissionLevelInput;
