import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Embed, models, Report, VisualDescriptor } from 'powerbi-client';
import styles from './ReportEmbedComponent.module.css';
import {
  EmbeddedReportSlicerValues,
  ReportEmbedComponentProps,
} from '../interfaces';
import {
  POWER_BI_VISUAL_TYPE,
  REPORT_PAGE_MAPPING,
  VALIDATION_SCREEN_PAGE_NAME,
  SLICER_TABLES_COLUMNS,
} from '../PowerBIEmbedded/PowerBIConstants';
import {
  getEmbedUrl,
  getSlicerConfig,
} from '../PowerBIEmbedded/PowerBiMethods';
import { useParams } from 'react-router';

const ReportEmbedComponent: React.FC<ReportEmbedComponentProps> = (
  props: ReportEmbedComponentProps
) => {
  const params = useParams();

  const embedDetails = params.pageDisplayName
    ? REPORT_PAGE_MAPPING.filter(
        (item) => item.pageDisplayName === params.pageDisplayName
      )[0]
    : REPORT_PAGE_MAPPING[0];
  document.title = 'BSR | ' + embedDetails.pageDisplayName;
  let embeddedReport: Report;

  // Update or set slicer values
  const updateReportSlicerValues = async (visuals: VisualDescriptor[]) => {
    const slicerList = visuals.filter(
      (visual) => visual.type === POWER_BI_VISUAL_TYPE.SLICER
    );

    const temporarySlicerValues: EmbeddedReportSlicerValues = Object.assign(
      {},
      JSON.parse(
        localStorage.getItem('slicers') || ''
      ) as EmbeddedReportSlicerValues
    );

    await Promise.all(
      slicerList.map(async (slicer) => {
        const slicerState = await slicer.getSlicerState();
        let slicerValue: string | string[];
        const filterSize = slicerState.filters.length;
        const slicerTarget = (slicerState.targets as models.IFilterColumnTarget[])[0];
        switch (slicerTarget.table + '/' + slicerTarget.column) {
          case SLICER_TABLES_COLUMNS.CURRENT_PERIOD_TABLE +
            '/' +
            SLICER_TABLES_COLUMNS.CURRENT_PERIOD_COLUMN:
            slicerValue =
              filterSize > 0
                ? ((slicerState.filters[0] as models.IBasicFilter)
                    .values as string[])
                : ([] as string[]);
            temporarySlicerValues.currentPeriod = slicerValue;
            temporarySlicerValues.currentPeriodOperator =
              filterSize > 0
                ? (slicerState.filters[0] as models.IBasicFilter).operator
                : 'All';
            break;
          case SLICER_TABLES_COLUMNS.COMPARISON_PERIOD_TABLE +
            '/' +
            SLICER_TABLES_COLUMNS.COMPARISON_PERIOD_COLUMN:
            slicerValue =
              filterSize > 0
                ? ((slicerState.filters[0] as models.IBasicFilter)
                    .values as string[])
                : ([] as string[]);
            temporarySlicerValues.comparisonPeriod = slicerValue;
            temporarySlicerValues.comparisonPeriodOperator =
              filterSize > 0
                ? (slicerState.filters[0] as models.IBasicFilter).operator
                : 'All';
            break;
          case SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE +
            '/' +
            SLICER_TABLES_COLUMNS.SECTOR_NAME_COLUMN:
            slicerValue =
              filterSize > 0
                ? ((slicerState.filters[0] as models.IBasicFilter)
                    .values as string[])
                : ([] as string[]);
            temporarySlicerValues.sectorName = slicerValue;
            temporarySlicerValues.sectorNameOperator =
              filterSize > 0
                ? (slicerState.filters[0] as models.IBasicFilter).operator
                : 'All';
            break;
          case SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE +
            '/' +
            SLICER_TABLES_COLUMNS.BU_NAME_COLUMN:
            slicerValue =
              filterSize > 0
                ? ((slicerState.filters[0] as models.IBasicFilter)
                    .values as string[])
                : ([] as string[]);
            temporarySlicerValues.buName = slicerValue;
            temporarySlicerValues.buNameOperator =
              filterSize > 0
                ? (slicerState.filters[0] as models.IBasicFilter).operator
                : 'All';
            break;
          case SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE +
            '/' +
            SLICER_TABLES_COLUMNS.MARKET_COLUMN:
            slicerValue =
              filterSize > 0
                ? ((slicerState.filters[0] as models.IBasicFilter)
                    .values as string[])
                : ([] as string[]);
            temporarySlicerValues.mktUnitName = slicerValue;
            temporarySlicerValues.mktUnitNameOperator =
              filterSize > 0
                ? (slicerState.filters[0] as models.IBasicFilter).operator
                : 'All';
            break;
          case SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE +
            '/' +
            SLICER_TABLES_COLUMNS.COMPANY_COLUMN:
            slicerValue =
              filterSize > 0
                ? ((slicerState.filters[0] as models.IBasicFilter)
                    .values as string[])
                : ([] as string[]);
            temporarySlicerValues.company = slicerValue;
            temporarySlicerValues.companyOperator =
              filterSize > 0
                ? (slicerState.filters[0] as models.IBasicFilter).operator
                : 'All';
            break;
          case SLICER_TABLES_COLUMNS.CURRENCY_VALUE_TABLE +
            '/' +
            SLICER_TABLES_COLUMNS.CURRENCY_VALUE_COLUMN:
            slicerValue =
              filterSize > 0
                ? ((slicerState.filters[0] as models.IBasicFilter)
                    .values as string[])
                : ([] as string[]);
            slicerValue =
              filterSize > 0
                ? ((slicerState.filters[0] as models.IBasicFilter)
                    .values as string[])
                : ([] as string[]);
            temporarySlicerValues.currencyValue = slicerValue;
            temporarySlicerValues.currencyValueOperator =
              filterSize > 0
                ? (slicerState.filters[0] as models.IBasicFilter).operator
                : 'All';
            break;
          case SLICER_TABLES_COLUMNS.CURRENCY_UNIT_TABLE +
            '/' +
            SLICER_TABLES_COLUMNS.CURRENCY_UNIT_COLUMN:
            if (embedDetails.pageDisplayName !== VALIDATION_SCREEN_PAGE_NAME) {
              slicerValue =
                filterSize > 0
                  ? ((slicerState.filters[0] as models.IBasicFilter)
                      .values as string[])
                  : ([] as string[]);
              temporarySlicerValues.currencyUnit = slicerValue;
              temporarySlicerValues.currencyUnitOperator =
                filterSize > 0
                  ? (slicerState.filters[0] as models.IBasicFilter).operator
                  : 'All';
            }
            break;
          case SLICER_TABLES_COLUMNS.ENTITY_TABLE +
            '/' +
            SLICER_TABLES_COLUMNS.ENTITY_COLUMN:
            slicerValue =
              filterSize > 0
                ? ((slicerState.filters[0] as models.IBasicFilter)
                    .values as string[])
                : ([] as string[]);
            temporarySlicerValues.entity = slicerValue;
            temporarySlicerValues.entityOperator =
              filterSize > 0
                ? (slicerState.filters[0] as models.IBasicFilter).operator
                : 'All';
            break;
          case SLICER_TABLES_COLUMNS.PROFIT_CENTER_TABLE +
            '/' +
            SLICER_TABLES_COLUMNS.PROFIT_CENTER_COLUMN:
            slicerValue =
              filterSize > 0
                ? ((slicerState.filters[0] as models.IBasicFilter)
                    .values as string[])
                : ([] as string[]);
            temporarySlicerValues.profitCenter = slicerValue;
            temporarySlicerValues.profitCenterOperator =
              filterSize > 0
                ? (slicerState.filters[0] as models.IBasicFilter).operator
                : 'All';
            break;
          case SLICER_TABLES_COLUMNS.REPORTING_LEVEL_TABLE +
            '/' +
            SLICER_TABLES_COLUMNS.REPORTING_LEVEL_COLUMN:
            slicerValue =
              filterSize > 0
                ? ((slicerState.filters[0] as models.IBasicFilter)
                    .values as string[])
                : ([] as string[]);
            temporarySlicerValues.reportingLevel = slicerValue;
            temporarySlicerValues.reportingLevelOperator =
              filterSize > 0
                ? (slicerState.filters[0] as models.IBasicFilter).operator
                : 'All';
            break;
          default:
            break;
        }
      })
    );
    localStorage.setItem('slicers', JSON.stringify(temporarySlicerValues));
  };

  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        console.clear();
      },
    ],
    [
      'rendered',
      async () => {
        const pages = await embeddedReport.getPages();
        // Getting the visuals for the active page
        const activePage = pages.filter((page) => page.isActive)[0];
        const visuals = await activePage.getVisuals();
        await updateReportSlicerValues(visuals);
      },
    ],
  ]);

  return (
    <>
      <PowerBIEmbed
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual and qna
          id: embedDetails.reportId,
          embedUrl: getEmbedUrl(embedDetails.groupId, embedDetails.reportId),
          accessToken: props.accessToken, //sessionStorage.getItem('msalPowerBiAccessToken') || '',
          tokenType: models.TokenType.Aad,
          pageName: embedDetails.pageId,
          settings: {
            bars: {
              actionBar: {
                visible: true,
              },
            },
            persistentFiltersEnabled: true,
            navContentPaneEnabled: false,
          },
          slicers: getSlicerConfig(
            JSON.parse(
              localStorage.getItem('slicers') || ''
            ) as EmbeddedReportSlicerValues,
            embedDetails.pageDisplayName
          ),
        }}
        cssClassName={styles.Embeddedreport}
        eventHandlers={eventHandlersMap}
        getEmbeddedComponent={async (embedObject: Embed) => {
          embeddedReport = embedObject as Report;
        }}
      />
    </>
  );
};

export default ReportEmbedComponent;
