import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DeleteModalProps } from '../interfaces';

// Popup window for Rejection, Submission and Approval
const DeleteConfirmation: React.FC<DeleteModalProps> = (
  props: DeleteModalProps
) => {
  return (
    <Modal
      onHide={props.setModal}
      show={props.showState}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to to delete the report line item?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.confirmDelete} variant='success'>
          Yes
        </Button>
        <Button onClick={props.setModal} variant='danger'>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteConfirmation;
