import React from 'react';
import { Container } from 'react-bootstrap';
import ErrorIcon from '../../images/FinalOutcomeReportImages/Error View.png';
import styles from './Util.module.css';

// Default screen to display error.

const ErrorScreen: React.FC = () => {
  return (
    <Container className={styles.screen}>
      <img src={ErrorIcon} alt='Error Icon' className={styles.icons} />
      <h5>Something went wrong.</h5>
    </Container>
  );
};

export default ErrorScreen;
