import { models } from 'powerbi-client';
import { REPORTINGLEVElSTRING, USERROLESTRING } from '../constants';
import {
  DefautPeriod,
  EmbeddedReportSlicerValues,
  UserDetailsObject,
} from '../interfaces';
import {
  POWER_BI_SLICER_CONSTANTS,
  SLICER_TABLES_COLUMNS,
  VALIDATION_SCREEN_PAGE_NAME,
} from './PowerBIConstants';

export const computeDeafualtPeriod = (): DefautPeriod => {
  const MONTH_FORMAT = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  const dateToday = new Date();
  const currentMonthNo = dateToday.getMonth();
  const currentYear =
    currentMonthNo === 0
      ? dateToday.getFullYear() - 1
      : dateToday.getFullYear();
  const defaultMonth =
    currentMonthNo !== 0 ? MONTH_FORMAT[currentMonthNo - 1] : MONTH_FORMAT[11];
  const comparisonYear = currentYear - 1;

  const defaultCurrentPeriod = defaultMonth + ' ' + currentYear;
  const defaultComparisonPeriod = defaultMonth + ' ' + comparisonYear;

  return {
    currentPeriod: defaultCurrentPeriod,
    comparisonPeriod: defaultComparisonPeriod,
  };
};

export const isPreparerOrReviewer = (
  userList: UserDetailsObject[]
): boolean => {
  const userRolesList = userList.map((item) => item.role);
  if (
    userRolesList.includes(USERROLESTRING.PREPARER) ||
    userRolesList.includes(USERROLESTRING.REVIEWER)
  ) {
    return true;
  }
  return true;
};

export const getInitialSlicerValue = (
  defaultValue: UserDetailsObject
): EmbeddedReportSlicerValues => {
  const defaultPeriod = computeDeafualtPeriod();
  return {
    //Set the period dynamically
    currentPeriod: [defaultPeriod.currentPeriod],
    currentPeriodOperator: 'In',
    comparisonPeriod: [defaultPeriod.comparisonPeriod],
    comparisonPeriodOperator: 'In',
    sectorName: [defaultValue.sectorName],
    sectorNameOperator: 'In',
    buName: [defaultValue.buName],
    buNameOperator: 'In',
    mktUnitName: [defaultValue.mktUnitName],
    mktUnitNameOperator: 'In',
    company: [defaultValue.compName],
    companyOperator: 'In',
    currencyValue: [POWER_BI_SLICER_CONSTANTS.CURRENCY_VALUE_USD],
    currencyValueOperator: 'In',
    currencyUnit: [POWER_BI_SLICER_CONSTANTS.CURRENCY_UNIT_MILLIONS],
    currencyUnitOperator: 'In',
    entity: [],
    entityOperator: 'All',
    profitCenter: [],
    profitCenterOperator: 'All',
    reportingLevel: [REPORTINGLEVElSTRING.COMPANY],
    reportingLevelOperator: 'In',
  };
};

export const getSlicerConfig = (
  slicerData: EmbeddedReportSlicerValues,
  pageDisplayName: string
): models.ISlicer[] => {
  //Defining Filters for the slicers
  const basicFilter: models.ISlicerFilter[] = [
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: SLICER_TABLES_COLUMNS.CURRENT_PERIOD_TABLE,
        column: SLICER_TABLES_COLUMNS.CURRENT_PERIOD_COLUMN,
      },
      operator: slicerData.currentPeriodOperator as models.BasicFilterOperators,
      values: slicerData.currentPeriod,
      filterType: models.FilterType.Basic,
    },
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: SLICER_TABLES_COLUMNS.COMPARISON_PERIOD_TABLE,
        column: SLICER_TABLES_COLUMNS.COMPARISON_PERIOD_COLUMN,
      },
      operator: slicerData.comparisonPeriodOperator as models.BasicFilterOperators,
      values: slicerData.comparisonPeriod,
      filterType: models.FilterType.Basic,
    },
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE,
        column: SLICER_TABLES_COLUMNS.SECTOR_NAME_COLUMN,
      },
      operator: slicerData.sectorNameOperator as models.BasicFilterOperators,
      values: slicerData.sectorName,
      filterType: models.FilterType.Basic,
    },
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE,
        column: SLICER_TABLES_COLUMNS.BU_NAME_COLUMN,
      },
      operator: slicerData.buNameOperator as models.BasicFilterOperators,
      values: slicerData.buName,
      filterType: models.FilterType.Basic,
    },
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE,
        column: SLICER_TABLES_COLUMNS.MARKET_COLUMN,
      },
      operator: slicerData.mktUnitNameOperator as models.BasicFilterOperators,
      values: slicerData.mktUnitName,
      filterType: models.FilterType.Basic,
    },
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE,
        column: SLICER_TABLES_COLUMNS.COMPANY_COLUMN,
      },
      operator: slicerData.companyOperator as models.BasicFilterOperators,
      values: slicerData.company,
      filterType: models.FilterType.Basic,
    },
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: SLICER_TABLES_COLUMNS.CURRENCY_VALUE_TABLE,
        column: SLICER_TABLES_COLUMNS.CURRENCY_VALUE_COLUMN,
      },
      operator: slicerData.currencyValueOperator as models.BasicFilterOperators,
      values: slicerData.currencyValue,
      filterType: models.FilterType.Basic,
    },
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: SLICER_TABLES_COLUMNS.CURRENCY_UNIT_TABLE,
        column: SLICER_TABLES_COLUMNS.CURRENCY_UNIT_COLUMN,
      },
      operator: slicerData.currencyUnitOperator as models.BasicFilterOperators,
      values:
        pageDisplayName === VALIDATION_SCREEN_PAGE_NAME
          ? [POWER_BI_SLICER_CONSTANTS.CURRENCY_UNIT_THOUSANDS]
          : slicerData.currencyUnit,

      filterType: models.FilterType.Basic,
    },
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: SLICER_TABLES_COLUMNS.ENTITY_TABLE,
        column: SLICER_TABLES_COLUMNS.ENTITY_COLUMN,
      },
      operator: slicerData.entityOperator as models.BasicFilterOperators,
      values: slicerData.entity,
      filterType: models.FilterType.Basic,
    },
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: SLICER_TABLES_COLUMNS.PROFIT_CENTER_TABLE,
        column: SLICER_TABLES_COLUMNS.PROFIT_CENTER_COLUMN,
      },
      operator: slicerData.profitCenterOperator as models.BasicFilterOperators,
      values: slicerData.profitCenter,
      filterType: models.FilterType.Basic,
    },
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: SLICER_TABLES_COLUMNS.REPORTING_LEVEL_TABLE,
        column: SLICER_TABLES_COLUMNS.REPORTING_LEVEL_COLUMN,
      },
      operator: slicerData.reportingLevelOperator as models.BasicFilterOperators,
      values: slicerData.reportingLevel,
      filterType: models.FilterType.Basic,
    },
  ];
  //Defining slicer states
  const GlobalSlicers: models.ISlicer[] = [
    {
      selector: {
        $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
        target: {
          table: SLICER_TABLES_COLUMNS.CURRENT_PERIOD_TABLE,
          column: SLICER_TABLES_COLUMNS.CURRENT_PERIOD_COLUMN,
        },
      },
      state: {
        filters: [basicFilter[0]],
      },
    },
    {
      selector: {
        $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
        target: {
          table: SLICER_TABLES_COLUMNS.COMPARISON_PERIOD_TABLE,
          column: SLICER_TABLES_COLUMNS.COMPARISON_PERIOD_COLUMN,
        },
      },
      state: {
        filters: [basicFilter[1]],
      },
    },
    {
      selector: {
        $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
        target: {
          table: SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE,
          column: SLICER_TABLES_COLUMNS.SECTOR_NAME_COLUMN,
        },
      },
      state: {
        filters:
          slicerData.sectorNameOperator === 'All' ? [] : [basicFilter[2]],
      },
    },
    {
      selector: {
        $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
        target: {
          table: SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE,
          column: SLICER_TABLES_COLUMNS.BU_NAME_COLUMN,
        },
      },
      state: {
        filters: slicerData.buNameOperator === 'All' ? [] : [basicFilter[3]],
      },
    },
    {
      selector: {
        $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
        target: {
          table: SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE,
          column: SLICER_TABLES_COLUMNS.MARKET_COLUMN,
        },
      },
      state: {
        filters:
          slicerData.mktUnitNameOperator === 'All' ? [] : [basicFilter[4]],
      },
    },
    {
      selector: {
        $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
        target: {
          table: SLICER_TABLES_COLUMNS.BSR_SECTOR_TABLE,
          column: SLICER_TABLES_COLUMNS.COMPANY_COLUMN,
        },
      },
      state: {
        filters: slicerData.companyOperator === 'All' ? [] : [basicFilter[5]],
      },
    },
    {
      selector: {
        $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
        target: {
          table: SLICER_TABLES_COLUMNS.CURRENCY_VALUE_TABLE,
          column: SLICER_TABLES_COLUMNS.CURRENCY_VALUE_COLUMN,
        },
      },
      state: {
        filters: [basicFilter[6]],
      },
    },
    {
      selector: {
        $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
        target: {
          table: SLICER_TABLES_COLUMNS.CURRENCY_UNIT_TABLE,
          column: SLICER_TABLES_COLUMNS.CURRENCY_UNIT_COLUMN,
        },
      },
      state: {
        filters: [basicFilter[7]],
      },
    },
    {
      selector: {
        $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
        target: {
          table: SLICER_TABLES_COLUMNS.ENTITY_TABLE,
          column: SLICER_TABLES_COLUMNS.ENTITY_COLUMN,
        },
      },
      state: {
        filters: slicerData.entityOperator === 'All' ? [] : [basicFilter[8]],
      },
    },
    {
      selector: {
        $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
        target: {
          table: SLICER_TABLES_COLUMNS.PROFIT_CENTER_TABLE,
          column: SLICER_TABLES_COLUMNS.PROFIT_CENTER_COLUMN,
        },
      },
      state: {
        filters:
          slicerData.profitCenterOperator === 'All' ? [] : [basicFilter[9]],
      },
    },
    {
      selector: {
        $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
        target: {
          table: SLICER_TABLES_COLUMNS.REPORTING_LEVEL_TABLE,
          column: SLICER_TABLES_COLUMNS.REPORTING_LEVEL_COLUMN,
        },
      },
      state: {
        filters: [basicFilter[10]],
      },
    },
  ];
  return GlobalSlicers;
};

export const getEmbedUrl = (groupId: string, reportId: string): string => {
  return `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}`;
};
