import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { TableViewProps } from '../interfaces';
import styles from './ReportLineItemContainer.module.css';
// Grid for displaying line items
// Displayed in case user does not have edit access. Useful when taking printout
const TableView: React.FC<TableViewProps> = (props: TableViewProps) => {
  return (
    <Container fluid={true} className={styles.tableContainer + ' ml-auto mt-3'}>
      <Table bordered hover size='sm'>
        <thead>
          <tr>
            <th>Reviewer Period</th>
            <th>Schedule</th>
            <th>BSR Observation</th>
            <th>Financial Impact</th>
            <th>Status</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {props.lineItems.map((item) => {
            return (
              <tr key={item.id}>
                <td>{item.reviewerPeriod}</td>
                <td>{item.schedule}</td>
                <td>{item.bsrObservation}</td>
                <td>{item.financialImpact}</td>
                <td>{item.status ? 'Closed' : 'In Progress'}</td>
                <td>{item.comments}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default TableView;
