// For updating between Dev and Prod use isProd
export const isProd = false;

// Below links are for Dev and Prod WebApp

const devWebAppLink =
  'https://balancesheetreviewdev.ext.mypepsico.com/OutcomeReport';
const prodWebAppLink =
  'https://balancesheetreview.ext.mypepsico.com/OutcomeReport';

export const webAppLink = isProd ? prodWebAppLink : devWebAppLink;

// Below credentials for Dev and Prod are for Pakistan report

const devSharePointLocation = {
  excelTemplateSharepointLocation:
    'https://pepsico.sharepoint.com/teams/BSR365/Shared%20Documents/Forms/AllItems.aspx?FolderCTID=0x01200072965000E5F7044BA42AE055391666D8&viewid=9ae2ec2c%2D0015%2D44ae%2Db5b7%2Df97b66edc761&id=%2Fteams%2FBSR365%2FShared%20Documents%2FGeneral%2FBSR%5FPOC%20Files%2FSnapshot%20Approach',
};

const prodSharePointLocation = {
  excelTemplateSharepointLocation:
    'https://pepsico.sharepoint.com/teams/BalanceSheetReviewReport/Shared%20Documents/Forms/AllItems.aspx?RootFolder=%2Fteams%2FBalanceSheetReviewReport%2FShared%20Documents%2FGeneral&FolderCTID=0x012000E799905A6DA489469E544B18558F4D13',
};

export const SHARE_POINT_LINKS = isProd
  ? prodSharePointLocation
  : devSharePointLocation;

export const POWER_BI_PAGE_DETAILS = {
  heatmap: 'ReportSection',
  summaryBS: 'ReportSectione38117f1c58fe814678b',
  hfmSapRecon: 'ReportSectiond518d3e2d52a0d40d4a0',
  cccWatchouts: 'ReportSection74901ba335ae7ce58419',
  blacklineSummary: 'ReportSectionce2d7ada7d487dd934d8',
  gcsSummary: 'ReportSectionf1c735225eeddc575182',
  cashflow: 'ReportSection0769182ed4708107512c',
  helper: 'ReportSection2e4a75b2a090ee2e013e',
  ccst: 'ReportSection1418506c3a23c8071545',
  ar: 'ReportSection64b321e45d5741ec501c',
  noar: 'ReportSection56ecb3c29abc6cad7c2c',
  inventory: 'ReportSectionb5a19ed96009a57c402a',
  prepaid: 'ReportSection070def86ea0ce40916e1',
  icar: 'ReportSectionb9ebbdeb5e9790a00672',
  capex: 'ReportSectionbc40a05019921838d155',
  rou: 'ReportSection2117750b0cc408488239',
  goodwill_otherAssets: 'ReportSection0c982297f72b54008e31',
  dta: 'ReportSectionc4e0c9e09ca90504e06d',
  currentDebt_leaseSt_leaseLt: 'ReportSection82e553aa016a22e7ad72',
  ap: 'ReportSection7c273897745c6d72a1c0',
  accruedTax: 'ReportSection8cf66029c60ede56ac97',
  dtl: 'ReportSection9719c1bbc3ccac040032',
  oal: 'ReportSectiond4a963aa94f3bd1e078b',
  icap: 'ReportSection5277805e007edcb66d0a',
  oltl: 'ReportSectionb08cbb65e7b39e6c9988',
  capitalStock_re_nci_cta: 'ReportSection1374d4ba754b97743296',
  validationScreen: 'ReportSection6d4e25e8c98178d00a41',
  pendingUload: 'ReportSection57a644ece4aa9529e0db',
  existingUload: 'ReportSectionff928f434b0d658b5707',
};

const devReportCredentials = {
  //QA Reports
  groupId: '25b8d35f-9262-46db-97c4-09f82f633e30',
  heatmapReportId: '993ef998-a39a-4dbc-b20c-495219bc86b5',
  helperReportId: 'be434885-b0da-47ae-9250-acdf534c11b0',
  assetsReportId: '6f633dc0-f374-44f9-a777-a9b67a3d8171',
  liabilitiesReportId: '6fe5dd31-b7f1-4b28-8d22-a5980b37581c',
};

const prodReportCredentials = {
  groupId: '9c01f71d-0176-4da9-85b8-20359a8e1103',
  heatmapReportId: 'cdee7692-9a0a-4a44-85ef-fbe0b36c90f1',
  helperReportId: '2db52041-bada-4cfc-a0e6-5f8be9dc46ea',
  assetsReportId: '230e0ff6-2e14-4e28-b781-e05b984fa1bc',
  liabilitiesReportId: 'b075188e-c69c-4b13-8d91-aa4c36131f70',
};

export const REPORT_SPLIT_DETAILS = isProd
  ? prodReportCredentials
  : devReportCredentials;
