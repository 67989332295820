import React, { useEffect, useState } from 'react';
import styles from './FilterSection.module.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { companyDetails, FilterSectionProps } from '../interfaces';
import {
  getReportingLevelCode,
  REPORTINGLEVELCODE,
  REPORTINGLEVElSTRING,
} from '../constants';
import { useParams, useNavigate } from 'react-router';

// Filter section to select Current Period, Reporting Level and Unit Code
const FilterSection: React.FC<FilterSectionProps> = (
  props: FilterSectionProps
) => {
  /* 
  Description: Function to get the array of current and previous 2 years  
  Output: Array of current and previous 2 years. Array size will be 3 
  */
  const initFilter = useParams();
  const navigate = useNavigate();
  const lastTwoYears = () => {
    const dateToday = new Date();
    const year = dateToday.getFullYear();
    const yrRange: number[] = [];
    for (let i = year; i >= year - 2; i--) {
      yrRange.push(i);
    }
    return yrRange;
  };

  // Available options for Reporting Level as a string
  const reportingLevelOptions: string[] = [
    REPORTINGLEVElSTRING.COMPANY,
    REPORTINGLEVElSTRING.MARKET,
    REPORTINGLEVElSTRING.BU,
    REPORTINGLEVElSTRING.SECTOR,
  ];

  /*
  initFilter will contain the relevant filter values when the values are passed through URL.
  isValidYear and isValidMkt checks if any invalid values are passed through URL.
  isValidYear checks if the year in the range of last 2 years since Workflow tool shows reports only for 2 Years.
  isValidMkt checks if the user has access to the market passed in the URL
  */
  const isValidYear =
    initFilter && lastTwoYears().includes(Number(initFilter.year));

  const isValidReportingLevel =
    initFilter.reportingLevel &&
    reportingLevelOptions.includes(initFilter.reportingLevel);
  const isValidUnitCode =
    props.unitCodeInformation.filter((items: companyDetails) => {
      if (
        initFilter.reportingLevel === REPORTINGLEVElSTRING.COMPANY &&
        items.CompCode === initFilter.unitCode
      ) {
        return items;
      } else if (
        initFilter.reportingLevel === REPORTINGLEVElSTRING.MARKET &&
        items.Market === initFilter.unitCode
      ) {
        return items;
      } else if (
        initFilter.reportingLevel === REPORTINGLEVElSTRING.BU &&
        items.BU === initFilter.unitCode
      ) {
        return items;
      } else if (
        initFilter.reportingLevel === REPORTINGLEVElSTRING.SECTOR &&
        items.Sector === initFilter.unitCode
      ) {
        return items;
      }
      return false;
    }).length > 0
      ? true
      : false;
  // Checking if correct filter values have been set through URL
  const [isFilterFromParams, setIsFilterFromParams] = useState(
    isValidYear &&
      isValidUnitCode &&
      isValidReportingLevel &&
      initFilter.unitCode
      ? true
      : false
  );

  //Current values of the dropdown
  const [curYear, setYear] = useState<number>(
    isValidYear ? Number(initFilter.year) : 0
  );
  const [curQuarter, setQuarter] = useState<number>(Number(initFilter.quarter));
  const [curReportingLevel, setReportingLevel] = useState<string>(
    initFilter.reportingLevel ?? ''
  );
  const [curUnitCode, setUnitCode] = useState<string>(
    initFilter.unitCode ?? ''
  );

  /*
  Array for availabe quarters and unit codes
  The Array is modified as the Year and Reporting Level dropdown selection respectively
  */
  const [availableQuarters, setAvailQuarters] = useState<number[]>([]);
  const [availableUnitCodes, setAvailableUnitCodes] = useState<string[]>([]);

  /*
    Description: Populating the array availableQuarters as per the selected Year. The userEffect is called whenever curYear Value changes.
  */
  useEffect(() => {
    const dateToday = new Date();
    const month = dateToday.getMonth() + 1;
    if (!curYear) {
      setAvailQuarters([]);
    } else if (curYear === dateToday.getFullYear()) {
      if (month <= 2) {
        setAvailQuarters([1]);
      } else if (month <= 5) {
        setAvailQuarters([2, 1]);
      } else if (month <= 8) {
        setAvailQuarters([3, 2, 1]);
      } else {
        setAvailQuarters([4, 3, 2, 1]);
      }
    } else {
      setAvailQuarters([4, 3, 2, 1]);
    }
    const tempQu = Number(initFilter.quarter);
    if (curYear === Number(initFilter.year) && tempQu > 0 && tempQu < 5)
      setQuarter(tempQu);
    else setQuarter(0);
  }, [curYear, initFilter.quarter, initFilter.year]);

  /*
    Description: Populating the array availableUnitCodes as per the selected Reporting Level. The useEffect is called whenever curReportingLevel Value changes.
  */
  useEffect(() => {
    const avail = props.unitCodeInformation.map((obj: companyDetails) => {
      switch (curReportingLevel) {
        case REPORTINGLEVElSTRING.SECTOR:
          return obj.Sector;
        case REPORTINGLEVElSTRING.BU:
          return obj.BU;
        case REPORTINGLEVElSTRING.MARKET:
          return obj.Market;
        default:
          return obj.CompCode;
      }
    });
    setAvailableUnitCodes(
      avail.filter((item, index, self) => self.indexOf(item) === index)
    );
    if (initFilter.unitCode && curReportingLevel === initFilter.reportingLevel)
      setUnitCode(initFilter.unitCode);
    else setUnitCode('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curReportingLevel, initFilter.unitCode, initFilter.reportingLevel]);

  /*
    Description: Sets the filter values in Final Outcome Report state variable. The method is called on clicking the launch BSR button.
  */
  const updateFilter = () => {
    if (
      curYear &&
      curQuarter !== 0 &&
      getReportingLevelCode(curReportingLevel) >= REPORTINGLEVELCODE.COMPANY &&
      getReportingLevelCode(curReportingLevel) <= REPORTINGLEVELCODE.SECTOR &&
      curUnitCode !== ''
    ) {
      props.setCurrentFilters({
        yearQuarter: curYear * 100 + curQuarter,
        reportingLevel: curReportingLevel,
        unitCode: curUnitCode,
      });
      navigate(
        `/OutcomeReport/${curYear}/${curQuarter}/${curReportingLevel}/${curUnitCode}`
      );
    }
  };

  // Launch BSR button will be clicked automatically if URL with corrects filters are used
  useEffect(() => {
    if (isFilterFromParams) {
      updateFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className='ml-auto mt-2' fluid>
      <Row>
        <Col className='m-auto' sm>
          <label className={styles.inputLabels}>Year</label>
          <select
            aria-label='year'
            className='form-control'
            onChange={(event) => {
              setYear(Number(event.target.value));
              setIsFilterFromParams(false);
            }}
            value={curYear}
          >
            <option>Select Year</option>
            {lastTwoYears().map((item: number, index: number) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        </Col>
        <Col className='m-auto' md>
          <label className={styles.inputLabels}>Quarter</label>
          <select
            aria-label='quarter'
            className='form-control'
            value={curQuarter}
            onChange={(event) => {
              setQuarter(Number(event.target.value));
              setIsFilterFromParams(false);
            }}
          >
            <option value='0'>Select Quarter</option>
            {availableQuarters.map((item: number, index: number) => (
              <option key={index} value={item}>
                Q-{item}
              </option>
            ))}
          </select>
        </Col>
        <Col className='m-auto' md>
          <label className={styles.inputLabels}>Reporting Level</label>
          <select
            aria-label='reporting-level'
            className='form-control'
            value={curReportingLevel}
            onChange={(event) => {
              setReportingLevel(event.target.value);
              setIsFilterFromParams(false);
            }}
          >
            <option value=''>Select Reporting Level</option>
            {reportingLevelOptions.map((item: string, index: number) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </Col>

        {getReportingLevelCode(curReportingLevel) >=
          REPORTINGLEVELCODE.COMPANY &&
          getReportingLevelCode(curReportingLevel) <=
            REPORTINGLEVELCODE.SECTOR && (
            <Col className='m-auto' md>
              <label className={styles.inputLabels}>{curReportingLevel}</label>
              <select
                aria-label='unit-code'
                className='form-control'
                value={curUnitCode}
                onChange={(event) => {
                  setUnitCode(event.target.value);
                  setIsFilterFromParams(false);
                }}
              >
                <option value=''>Select {curReportingLevel}</option>
                {availableUnitCodes.map((val: string, index: number) => (
                  <option key={index} value={val}>
                    {val}
                  </option>
                ))}
              </select>
            </Col>
          )}
        <Col className='m-auto' md={2}>
          <Button
            className={styles.searchButton}
            variant='primary'
            onClick={updateFilter}
          >
            Launch BSR
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default FilterSection;
