import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ActivityLogComponent from '../ActivityLogComponent/ActivityLogComponent';
import { ActivityLogModalProps } from '../interfaces';
import styles from './ActivityLogModal.module.css';

// Popup screen to display activity logs
const ActivityLogModal: React.FC<ActivityLogModalProps> = (
  props: ActivityLogModalProps
) => {
  const getActivityLogTitle = (
    unitCode: string,
    yearQuarter: number,
    reportingLevel: string
  ): string => {
    return `${unitCode} BSR Report ${Math.floor(yearQuarter / 100)} Q-${
      yearQuarter % 100
    } (Reporting at ${reportingLevel})`;
  };

  return (
    <Modal
      size='lg'
      onHide={props.setActivityView}
      show={props.showState}
      animation={false}
      dialogClassName='modal-xl'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Activity Log</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.selection}>
          {getActivityLogTitle(
            props.currentFilter.unitCode,
            props.currentFilter.yearQuarter,
            props.currentFilter.reportingLevel
          )}
        </div>
        <ActivityLogComponent logs={props.logs} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.setActivityView}>Go Back</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActivityLogModal;
