import { webAppLink } from '../config/powerBiReportDetails';
import { EmailRecipientAndGmCfoDcCountObject } from './interfaces';

export const DISPLAY = {
  POWERBI: 'embedded',
  WORKFLOW: 'workflowReport',
  AUTHENTICATED: 'authenticated',
  NOT_AUTH: 'unauthenticated',
  SUCCESS: 'success',
  LOADING: 'loading',
  ERROR: 'error',
  DISABLED: 'disabled',
  DEFAULT: 'default',
  ACTIVITY_LOAD: 'activityload',
  ACTIVITY_VIEW: 'activityview',
};

export const AUTHDISPLAY = {
  AUTHENTICATED: 'authenticated',
  NOT_AUTH: 'unauthenticated',
};

export const ERRORS = {
  ACCESS_ON_REPORT:
    'Please check your access permissions. It seems like you dont have access on Balance Sheet Review.',
  AUTHENTICATION_ERROR:
    'You are not authenticated. Please check your credentials.',
  INVALID_SUBMIT_ALERT:
    'Please fill all the fields and save line items before submission.',
  INVALID_SAVE_ALERT: 'Please save line items before submission.',
};

export const REPORTLINESTATUS = {
  INPROGRESS: 'In Progress',
  CLOSED: 'Closed',
};

export const REPORTSTATUSCODE = {
  REJECTED: -1,
  NOTPREPARED: 0,
  PREPARED: 1,
  REVIEWED: 2,
  APPROVED: 3,
  INTER_ACCEPTED: 4,
  ACCEPTED: 5,
  SUBMITTED: 6,
  DEFAULT: 100,
};

export const REPORTSTATUSSTRING = {
  REJECTED: 'Rejected',
  NOTPREPARED: 'Not Prepared',
  PREPARED: 'Prepared',
  REVIEWED: 'Reviewed',
  APPROVED: 'Approved',
  INTER_ACCEPTED: 'Acceptance in Progress',
  ACCEPTED: 'Accepted',
  SUBMITTED: 'Submitted',
};

export const USERROLECODE = {
  PREPARER: 0,
  REVIEWER: 1,
  APPROVER: 2,
  GM_CFO: 3,
  CONTROLLER: 5,
  VIEWER: 6,
  DEFAULT: 100,
};

export const USERROLESTRING = {
  PREPARER: 'Preparer',
  REVIEWER: 'Reviewer',
  APPROVER: 'Controller',
  GM_CFO: 'GM & CFO',
  CONTROLLER: 'Designated Controller',
  VIEWER: 'Viewer',
};

export const OUTCOME = {
  APPROVE: 'Submit',
  REJECT: 'Reject',
  SAVE: 'Save',
};

export const GMCFOMAILINGFLAG = {
  SEND_IN_PROGRESS_MAIL: 0,
  SEND_ACCEPTED_MAIL: 1,
  SEND_SUBMITTED_MAIL: 2,
  NOT_GM_CFO: -1,
};

export const MAILINGTYPE = {
  FINAL_SUBMISSION_MAIL: 1,
  APPROVAL_MAIL: 0,
  REJECTION_MAIL: -1,
};

export const REPORTINGLEVELCODE = {
  COMPANY: 1,
  MARKET: 2,
  BU: 3,
  SECTOR: 4,
};

export const REPORTINGLEVElSTRING = {
  COMPANY: 'Company Code',
  MARKET: 'Market',
  BU: 'BU',
  SECTOR: 'Sector',
};

export const getUserRoleCode = (userRole: string): number => {
  switch (userRole) {
    case USERROLESTRING.PREPARER:
      return USERROLECODE.PREPARER;
    case USERROLESTRING.REVIEWER:
      return USERROLECODE.REVIEWER;
    case USERROLESTRING.APPROVER:
      return USERROLECODE.APPROVER;
    case USERROLESTRING.GM_CFO:
      return USERROLECODE.GM_CFO;
    case USERROLESTRING.CONTROLLER:
      return USERROLECODE.CONTROLLER;
    case USERROLESTRING.VIEWER:
      return USERROLECODE.VIEWER;
  }
  return 100;
};

export const getUserRoleString = (userRoleCode: number): string => {
  switch (Number(userRoleCode)) {
    case USERROLECODE.PREPARER:
      return USERROLESTRING.PREPARER;
    case USERROLECODE.REVIEWER:
      return USERROLESTRING.REVIEWER;
    case USERROLECODE.APPROVER:
      return USERROLESTRING.APPROVER;
    case USERROLECODE.GM_CFO:
      return USERROLESTRING.GM_CFO;
    case USERROLECODE.CONTROLLER:
      return USERROLESTRING.CONTROLLER;
    case USERROLECODE.VIEWER:
      return USERROLESTRING.VIEWER;
  }
  return '';
};

export const getReportStatusCode = (status: string): number => {
  switch (status) {
    case REPORTSTATUSSTRING.REJECTED:
      return REPORTSTATUSCODE.REJECTED;
    case REPORTSTATUSSTRING.NOTPREPARED:
      return REPORTSTATUSCODE.NOTPREPARED;
    case REPORTSTATUSSTRING.PREPARED:
      return REPORTSTATUSCODE.PREPARED;
    case REPORTSTATUSSTRING.REVIEWED:
      return REPORTSTATUSCODE.REVIEWED;
    case REPORTSTATUSSTRING.APPROVED:
      return REPORTSTATUSCODE.APPROVED;
    case REPORTSTATUSSTRING.INTER_ACCEPTED:
      return REPORTSTATUSCODE.INTER_ACCEPTED;
    case REPORTSTATUSSTRING.ACCEPTED:
      return REPORTSTATUSCODE.ACCEPTED;
    case REPORTSTATUSSTRING.SUBMITTED:
      return REPORTSTATUSCODE.SUBMITTED;
  }
  return 100;
};

export const getReportStatusString = (statusCode: number): string => {
  switch (Number(statusCode)) {
    case REPORTSTATUSCODE.REJECTED:
      return REPORTSTATUSSTRING.REJECTED;
    case REPORTSTATUSCODE.NOTPREPARED:
      return REPORTSTATUSSTRING.NOTPREPARED;
    case REPORTSTATUSCODE.PREPARED:
      return REPORTSTATUSSTRING.PREPARED;
    case REPORTSTATUSCODE.REVIEWED:
      return REPORTSTATUSSTRING.REVIEWED;
    case REPORTSTATUSCODE.APPROVED:
      return REPORTSTATUSSTRING.APPROVED;
    case REPORTSTATUSCODE.INTER_ACCEPTED:
      return REPORTSTATUSSTRING.INTER_ACCEPTED;
    case REPORTSTATUSCODE.ACCEPTED:
      return REPORTSTATUSSTRING.ACCEPTED;
    case REPORTSTATUSCODE.SUBMITTED:
      return REPORTSTATUSSTRING.SUBMITTED;
  }
  return '';
};

export const getReportingLevelCode = (reportingLevelString: string): number => {
  switch (reportingLevelString) {
    case REPORTINGLEVElSTRING.COMPANY:
      return REPORTINGLEVELCODE.COMPANY;
    case REPORTINGLEVElSTRING.MARKET:
      return REPORTINGLEVELCODE.MARKET;
    case REPORTINGLEVElSTRING.BU:
      return REPORTINGLEVELCODE.BU;
    case REPORTINGLEVElSTRING.SECTOR:
      return REPORTINGLEVELCODE.SECTOR;
  }
  return 100;
};

export const getReportingLevelString = (reportingLevelCode: number): string => {
  switch (Number(reportingLevelCode)) {
    case REPORTINGLEVELCODE.COMPANY:
      return REPORTINGLEVElSTRING.COMPANY;
    case REPORTINGLEVELCODE.MARKET:
      return REPORTINGLEVElSTRING.MARKET;
    case REPORTINGLEVELCODE.BU:
      return REPORTINGLEVElSTRING.BU;
    case REPORTINGLEVELCODE.SECTOR:
      return REPORTINGLEVElSTRING.SECTOR;
  }
  return '';
};

export const FINALSUBMISSIONCODE = {
  CONTROLLER: 1,
  GM_CFO: 2,
  DESIGNATED_CONTROLLER: 3,
  GM_CFO_DESIGNATED_CONTROLLER: 4,
};

export const FINALSUBMISSIONSTRING = {
  CONTROLLER: 'Controller',
  GM_CFO: 'GM and CFO',
  DESIGNATED_CONTROLLER: 'Designated Controller',
  GM_CFO_DESIGNATED_CONTROLLER: 'GM and CFO, Designated Controller',
};

export const getReportStatusAfterSubmit = (
  userRole: number,
  currentReportStatus: number,
  finalSubmissionLevel: number
): string => {
  let statusAfterApproval = currentReportStatus + 1;
  if (userRole === USERROLECODE.APPROVER) {
    switch (finalSubmissionLevel) {
      case FINALSUBMISSIONCODE.CONTROLLER:
        statusAfterApproval = REPORTSTATUSCODE.SUBMITTED;
        break;
      case FINALSUBMISSIONCODE.GM_CFO:
        statusAfterApproval = REPORTSTATUSCODE.APPROVED;
        break;
      case FINALSUBMISSIONCODE.DESIGNATED_CONTROLLER:
        statusAfterApproval = REPORTSTATUSCODE.ACCEPTED;
        break;
      case FINALSUBMISSIONCODE.GM_CFO_DESIGNATED_CONTROLLER:
        statusAfterApproval = REPORTSTATUSCODE.APPROVED;
        break;
    }
  }
  return getReportStatusString(statusAfterApproval);
};

// needs to be checked again - check how the url should be updated, reporting level to be added in the link
export const getMailSubjectAndBody = (
  mailType: number,
  reportingLevel: string,
  unitCode: string,
  yearQuarter: number,
  statusAfterApproval: string,
  userName: string,
  userRole: string,
  comment: string
): string[] => {
  let subject = '';
  let body = '';
  const yearQuarterString = `${Math.floor(yearQuarter / 100)} Q-${
    yearQuarter % 100
  }`;
  const year = Math.floor(yearQuarter / 100);
  const quarter = yearQuarter % 100;
  let bsrUrl = `${webAppLink}/${year}/${quarter}/${reportingLevel}/${unitCode}`;
  bsrUrl = bsrUrl.replaceAll(' ', '%20');
  let nextReportStatus = getReportStatusCode(statusAfterApproval);
  nextReportStatus =
    nextReportStatus === REPORTSTATUSCODE.INTER_ACCEPTED
      ? REPORTSTATUSCODE.APPROVED
      : nextReportStatus;
  let nextLevelUserRole = getUserRoleString(nextReportStatus);
  nextLevelUserRole =
    nextLevelUserRole === USERROLESTRING.GM_CFO ? 'GM/CFO' : nextLevelUserRole;
  const statusText =
    mailType === MAILINGTYPE.FINAL_SUBMISSION_MAIL
      ? `Completed`
      : mailType === MAILINGTYPE.REJECTION_MAIL
      ? `${REPORTSTATUSSTRING.REJECTED}`
      : statusAfterApproval === REPORTSTATUSSTRING.INTER_ACCEPTED
      ? `Accepted (Require approval from remaining GM & CFO)`
      : statusAfterApproval;

  // needs to be checked again - unit code and reporting level to be added in the meeting subject
  subject = `${
    mailType === MAILINGTYPE.FINAL_SUBMISSION_MAIL ? `Submission for ` : ``
  }${unitCode} BSR Report for ${yearQuarterString} has been ${statusText}.`;

  // needs to be checked again - unit code and reporting level to be added in the meeting subject
  body = `
  <p>Dear ${
    mailType === MAILINGTYPE.FINAL_SUBMISSION_MAIL
      ? `Team`
      : `${nextLevelUserRole}`
  },</p>
  <p>BSR Report for ${unitCode} ${yearQuarterString} has been ${
    mailType === MAILINGTYPE.FINAL_SUBMISSION_MAIL
      ? `submitted successfully.<br/>
      The report has been approved by ${userName} (${userRole}).</p>`
      : `${statusText} by ${userName} (${userRole}). </p>`
  }  ${
    comment.length === 0
      ? ``
      : `<p> Following comment has been provided:</p><p> ${comment} <p>`
  } ${
    mailType === MAILINGTYPE.FINAL_SUBMISSION_MAIL
      ? ``
      : `<p>As a ${nextLevelUserRole}, you need to continue with the required actions.</p>`
  }<p>You may view the report using this link: <a href='${bsrUrl}'>Link</a><br/>(Preferred browsers to access the link - Google Chrome, Microsoft Edge).</p>
  <p>Thanks a lot for your efforts!</p>
  <p>Regards,<br/>BSR Team</p>
  `;

  const signature = `Regards, \n BSR Team`;

  return [subject, body, signature];
};

export const DEFAULT_EMAIL_GM_CFO_LIST: EmailRecipientAndGmCfoDcCountObject = {
  approveCcList: '',
  rejectCcList: '',
  rejectToList: '',
  approveToList: '',
  dcListForGmCfo: '',
  gmCfoCount: 0,
  dcCount: 0,
};
