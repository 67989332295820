import React from 'react';
import { Container } from 'react-bootstrap';
import DisabledIcon from '../../images/FinalOutcomeReportImages/Disabled View Icon.png';
import styles from './Util.module.css';

// Screen displayed when Final Outcome Report has not reached user's role for selected filter.

const DisabledScreen: React.FC = () => {
  return (
    <Container className={styles.screen}>
      <img src={DisabledIcon} alt='Disable Icon' className={styles.icons} />
      <h5>Please wait for the report to reach your level.</h5>
    </Container>
  );
};

export default DisabledScreen;
