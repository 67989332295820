import React from 'react';
import { InputGroup, FormControl, Container } from 'react-bootstrap';
import { ReportCommentProps } from '../interfaces';

// Latest comments from 2 neighboring user roles (D+1 and D-1), if there are any comments
// Editable: No
// Viewable: If user has edit access on complete report
const ReportComment: React.FC<ReportCommentProps> = (
  props: ReportCommentProps
) => {
  const content = props.comments
    .map((item) => {
      return `(${item.userRole}) ${item.userEmail}: ${item.comment}`;
    })
    .join('\n');

  return (
    <Container fluid={true} className='ml-auto mt-2'>
      <label style={{ font: 'normal normal bold 1rem Segoe UI' }}>
        Feedback Comment
      </label>
      <InputGroup size='lg' style={{ height: '20vh' }}>
        <FormControl
          as='textarea'
          aria-label='With textarea'
          value={content}
          disabled
        />
      </InputGroup>
    </Container>
  );
};

export default ReportComment;
