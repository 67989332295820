import { Tabs, Tab } from 'react-bootstrap';
import styles from './NavigationTab.module.css';
import HomeIcon from '../../images/NavigationTabImages/HomeIcon.png';
import ReportIcon from '../../images/NavigationTabImages/ReportIcon.png';
import React, { useEffect } from 'react';
import { NavigationTabProps } from '../interfaces';
import { DISPLAY } from '../constants';
import { useNavigate, useLocation } from 'react-router-dom';

// Navigation tabs to swith between Power BI embedded report and Final Outcome report
const NavigationTab: React.FC<NavigationTabProps> = (
  props: NavigationTabProps
) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.includes('/Report')) {
      props.setSelectedView(DISPLAY.POWERBI);
    } else if (location.pathname.includes('/OutcomeReport')) {
      props.setSelectedView(DISPLAY.WORKFLOW);
    }
  }, [location, props]);
  return (
    <Tabs
      className='mt-2'
      activeKey={props.selectedView}
      transition={false}
      onSelect={(k) => {
        k && props.setSelectedView(k);
        if (k === DISPLAY.POWERBI) {
          navigate('/Report');
        } else {
          navigate('/OutcomeReport');
        }
      }}
    >
      <Tab
        eventKey={DISPLAY.POWERBI}
        tabClassName={
          location.pathname.includes('/Report')
            ? styles.ActiveTab
            : styles.InActiveTab
        }
        title={
          <div>
            <img src={HomeIcon} alt='Home Icon' className={styles.tabIcons} />
            <span className={styles.tabTitle}>Power BI</span>
          </div>
        }
      />
      <Tab
        eventKey={DISPLAY.WORKFLOW}
        tabClassName={
          location.pathname.includes('/OutcomeReport')
            ? styles.ActiveTab
            : styles.InActiveTab
        }
        title={
          <div>
            <img
              src={ReportIcon}
              alt='Report Icon'
              className={styles.tabIcons}
            />
            <span className={styles.tabTitle}>Final Outcome Report</span>
          </div>
        }
      />
    </Tabs>
  );
};

export default NavigationTab;
