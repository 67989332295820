import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { SaveModalProps } from '../interfaces';

// Popup window for save confirmation
const SaveModal: React.FC<SaveModalProps> = (props: SaveModalProps) => {
  return (
    <Modal
      onHide={props.setModal}
      show={props.showState}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Save</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to save your progress?</p>
        <p>
          All the data entered will be saved. To submit click on Save and
          Submit.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.saveUpdate} variant='success'>
          Yes
        </Button>
        <Button onClick={props.setModal} variant='danger'>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveModal;
