import React, { useEffect, useState } from 'react';
import { getPowerBiAccessToken } from '../authentication';
import { PowerBIProps } from '../interfaces';
import { getInitialSlicerValue, isPreparerOrReviewer } from './PowerBiMethods';
import ReportNavigationTab from './ReportNavigationTab';
import ReportEmbedComponent from '../ReportEmbedComponent/ReportEmbedComponent';
import { Navigate, Route, Routes } from 'react-router-dom';

const PowerBIEmbedded: React.FC<PowerBIProps> = (props: PowerBIProps) => {
  const [accessToken, setAccessToken] = useState<string>('');
  const [tokenExpiry, setTokenExpiry] = useState<Date>(new Date());
  if (!localStorage.getItem('slicers')) {
    localStorage.setItem(
      'slicers',
      JSON.stringify(getInitialSlicerValue(props.userDataList[0]))
    );
  }
  const [displayHelperSchedules, setdisplayHelperSchedules] = useState(false);

  useEffect(() => {
    setdisplayHelperSchedules(isPreparerOrReviewer(props.userDataList));
    const getAccessToken = async () => {
      try {
        const tokenResponse = await getPowerBiAccessToken();
        setAccessToken(tokenResponse.accessToken);
        setTokenExpiry(tokenResponse.expiry);
      } catch (err) {
        console.log('You do not have access to view the report');
        console.log(err);
      }
    };
    getAccessToken();
  }, [props.userDataList]);

  const checkAndUpdateToken = async () => {
    const currentTime = Date.parse(Date());
    const expiration = tokenExpiry.getTime();
    if (expiration <= currentTime) {
      const tokenResponse = await getPowerBiAccessToken();
      setAccessToken(tokenResponse.accessToken);
      setTokenExpiry(tokenResponse.expiry);
    }
  };
  const INTERVAL_TIME = 300000;
  setInterval(checkAndUpdateToken, INTERVAL_TIME);
  return (
    <>
      <ReportNavigationTab displayHelperSchedules={displayHelperSchedules} />
      <Routes>
        <Route
          path=':reportType/:pageDisplayName'
          element={<ReportEmbedComponent accessToken={accessToken} />}
        />
        <Route
          path=''
          element={<ReportEmbedComponent accessToken={accessToken} />}
        />
      </Routes>
    </>
  );
};

export default PowerBIEmbedded;
