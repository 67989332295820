import React, { useEffect, useRef, useState } from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { ReportLineItemRowProps } from '../interfaces';
import styles from './ReportLineItemRow.module.css';
import EditIcon from '../../images/ReportLineItems/editIcon.png';
import DeleteIcon from '../../images/ReportLineItems/deleteIcon.png';
import SaveIcon from '../../images/ReportLineItems/saveIcon.png';
import { REPORTLINESTATUS } from '../constants';

// View for each line item
const ReportLineItemRow: React.FC<ReportLineItemRowProps> = (
  props: ReportLineItemRowProps
) => {
  const styleForInput = { backgroundColor: '#FFFFFF' };
  const [itemReadMode, setItemReadMode] = useState<boolean>(true);
  const [newReviewerPeriod, setNewReviewerPeriod] = useState<string>(
    props.data.reviewerPeriod
  );
  const [newSchedule, setNewSchedule] = useState<string>(props.data.schedule);
  const [newBsrObservation, setNewBsrObservation] = useState<string>(
    props.data.bsrObservation
  );
  const [newFinancialImpact, setNewFinancialImpact] = useState<string>(
    props.data.financialImpact
  );
  const [newStatus, setNewStatus] = useState<boolean>(props.data.status);
  const [newComments, setNewComments] = useState<string>(props.data.comments);

  const reviewPeriodInput = useRef<HTMLTextAreaElement>(null);
  const scheduleInput = useRef<HTMLTextAreaElement>(null);
  const ObservationInput = useRef<HTMLTextAreaElement>(null);
  const impactInput = useRef<HTMLTextAreaElement>(null);
  const commentInput = useRef<HTMLTextAreaElement>(null);

  /* 
    Description: Function to update height of textbox based upon the content
    Input: Reference of the UI element
  */
  const resizeTextBox = (elementRef: React.RefObject<HTMLTextAreaElement>) => {
    if (elementRef.current) {
      elementRef.current.style.height = elementRef.current.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    if (reviewPeriodInput.current) {
      reviewPeriodInput.current.style.height =
        reviewPeriodInput.current.scrollHeight + 'px';
    }
    if (scheduleInput.current) {
      scheduleInput.current.style.height =
        scheduleInput.current.scrollHeight + 'px';
    }
    if (ObservationInput.current) {
      ObservationInput.current.style.height =
        ObservationInput.current.scrollHeight + 'px';
    }
    if (impactInput.current) {
      impactInput.current.style.height =
        impactInput.current.scrollHeight + 'px';
    }
    if (commentInput.current) {
      commentInput.current.style.height =
        commentInput.current.scrollHeight + 'px';
    }
  }, []);
  // const resetValues = () => {
  //   setNewReviewerPeriod(props.data.reviewerPeriod);
  //   setNewSchedule(props.data.schedule);
  //   setNewBsrObservation(props.data.bsrObservation);
  //   setNewFinancialImpact(props.data.financialImpact);
  //   setNewStatus(props.data.status);
  //   setNewComments(props.data.comments);
  //   setReadMode(true);
  // };

  /* 
    Description: Function to save changes for current line item
    Input: Using state variable
    Output: Updates state variable
  */
  const saveLineItem = () => {
    const newLineItem = {
      ...props.data,
      reviewerPeriod: newReviewerPeriod,
      schedule: newSchedule,
      bsrObservation: newBsrObservation,
      financialImpact: newFinancialImpact,
      status: newStatus,
      comments: newComments,
      isUpdated: 1,
    };
    props.updateItem(newLineItem);
  };

  return (
    <Row className='mt-1'>
      <Col lg={1} md={1} sm={1}>
        <label className={styles.inputLabels}>Review Period</label>
        <FormControl
          as='textarea'
          ref={reviewPeriodInput}
          rows={1}
          value={newReviewerPeriod}
          maxLength={100}
          readOnly={itemReadMode || props.data.isCarriedForward}
          onChange={(event) => {
            resizeTextBox(reviewPeriodInput);
            setNewReviewerPeriod(event.target.value);
          }}
          className={
            newReviewerPeriod.length === 0
              ? styles.invalidInput + ' ' + styles.inputField
              : styles.inputField
          }
          style={styleForInput}
          title={newReviewerPeriod}
        />
        <label className={styles.counterLabel}>
          {newReviewerPeriod.length}/100
        </label>
      </Col>
      <Col lg={2} md={2} sm={2}>
        <label className={styles.inputLabels}>Schedule</label>
        <FormControl
          as='textarea'
          ref={scheduleInput}
          rows={1}
          value={newSchedule}
          maxLength={100}
          readOnly={itemReadMode || props.data.isCarriedForward}
          onChange={(event) => {
            resizeTextBox(scheduleInput);
            setNewSchedule(event.target.value);
          }}
          className={
            newSchedule.length === 0
              ? styles.invalidInput + ' ' + styles.inputField
              : styles.inputField
          }
          style={styleForInput}
          title={newSchedule}
        />
        <label className={styles.counterLabel}>{newSchedule.length}/100</label>
      </Col>
      <Col lg={3} md={2} sm={2}>
        <label className={styles.inputLabels}>BSR Observations</label>
        <FormControl
          as='textarea'
          ref={ObservationInput}
          rows={1}
          value={newBsrObservation}
          maxLength={500}
          readOnly={itemReadMode}
          onChange={(event) => {
            resizeTextBox(ObservationInput);
            setNewBsrObservation(event.target.value);
          }}
          className={
            newBsrObservation.length === 0
              ? styles.invalidInput + ' ' + styles.inputField
              : styles.inputField
          }
          style={styleForInput}
          title={newBsrObservation}
        />
        <label className={styles.counterLabel}>
          {newBsrObservation.length}/500
        </label>
      </Col>
      <Col lg={1} md={2} sm={2}>
        <label className={styles.inputLabels}>Financial Impact (USD)</label>
        <FormControl
          as='textarea'
          ref={impactInput}
          rows={1}
          value={newFinancialImpact}
          maxLength={100}
          readOnly={itemReadMode}
          onChange={(event) => {
            resizeTextBox(impactInput);
            setNewFinancialImpact(event.target.value);
          }}
          className={
            newFinancialImpact.length === 0
              ? styles.invalidInput + ' ' + styles.inputField
              : styles.inputField
          }
          style={styleForInput}
          title={newFinancialImpact}
        />
        <label className={styles.counterLabel}>
          {newFinancialImpact.length}/100
        </label>
      </Col>
      <Col lg={1} md={2} sm={2}>
        <label className={styles.inputLabels}>Status</label>
        <select
          className='form-control'
          disabled={itemReadMode}
          value={newStatus ? 'Closed' : 'In Progress'}
          onChange={(event) => {
            event.target.value === 'Closed'
              ? setNewStatus(true)
              : setNewStatus(false);
          }}
          style={styleForInput}
        >
          <option value={REPORTLINESTATUS.INPROGRESS}>In Progress</option>
          <option value={REPORTLINESTATUS.CLOSED}>Closed</option>
        </select>
      </Col>
      <Col lg={3} md={2} sm={2}>
        <label className={styles.inputLabels}>Comments</label>
        <FormControl
          as='textarea'
          ref={commentInput}
          rows={1}
          value={newComments}
          maxLength={500}
          readOnly={itemReadMode}
          onChange={(event) => {
            resizeTextBox(commentInput);
            setNewComments(event.target.value);
          }}
          style={styleForInput}
          className={styles.inputField}
          title={newComments}
        />
        <label className={styles.counterLabel}>{newComments.length}/500</label>
      </Col>
      <Col className={styles.buttonSection} lg={1} md={1} sm={1}>
        {!props.reportEditMode ? null : itemReadMode ? (
          <>
            <button
              title={'Edit the item'}
              onClick={() => {
                props.setUnsavedItemCounter(props.unsavedItemCounter + 1);
                setItemReadMode(false);
              }}
              className={styles.actionButton + ' ml-auto mb-1'}
            >
              <img className={styles.icon} src={EditIcon} alt='edit item' />
            </button>{' '}
            {props.data.isCarriedForward ? null : (
              <button
                title={'Delete the item'}
                onClick={() => {
                  props.deleteItem(props.data.id, props.data.isExistingRecord);
                }}
                className={styles.actionButton + ' ml-auto mb-1'}
              >
                <img
                  className={styles.icon}
                  src={DeleteIcon}
                  alt='delete the item'
                />
              </button>
            )}
          </>
        ) : (
          <>
            <button
              title={'Save the item'}
              onClick={() => {
                props.setUnsavedItemCounter(props.unsavedItemCounter - 1);
                saveLineItem();
                setItemReadMode(true);
              }}
              className={styles.invalidInput + ' ml-2 mb-1'}
            >
              <img className={styles.icon} src={SaveIcon} alt='save change' />
            </button>{' '}
          </>
        )}
      </Col>
    </Row>
  );
};

export default ReportLineItemRow;
