import { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import './App.css';
import Header from './components/Header/Header';
import NavigationTab from './components/NavigationTab/NavigationTab';
import PowerBIEmbedded from './components/PowerBIEmbedded/PowerBIEmbedded';
import FinalOutcomeReport from './components/FinalOutcomeReport/FinalOutcomeReport';
import HandleLogin from './components/HandleLogin/HandleLogin';
import { UserDetailsObject } from './components/interfaces';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { getLoggedInUserDetails } from './components/apiCalls';
import ErrorContainer from './components/UtilContainer/ErrorContainer';
import { DISPLAY, ERRORS } from './components/constants';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from 'react-router-dom';

const App: React.FC = () => {
  type ValueOf<T> = T[keyof T];
  const [selectedView, setSelectedView] = useState<string>(DISPLAY.POWERBI);
  document.title =
    selectedView === DISPLAY.POWERBI
      ? 'BSR Application'
      : 'BSR | Final Outcome Report';
  const [displayStatus, setDisplayStatus] = useState<string>(DISPLAY.LOADING);
  const [authenticationState, setAuthenticationState] = useState<
    ValueOf<typeof DISPLAY>
  >(DISPLAY.LOADING);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [userDetails, setUserDetails] = useState<UserDetailsObject[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (authenticationState === DISPLAY.AUTHENTICATED) {
        try {
          const userDataResponse = await getLoggedInUserDetails(
            sessionStorage.getItem('userEmail') || ''
          );
          setUserDetails(userDataResponse);
          if (userDataResponse.length === 0) {
            setErrorMessage(ERRORS.ACCESS_ON_REPORT);
            setDisplayStatus(DISPLAY.ERROR);
          } else {
            setDisplayStatus(DISPLAY.SUCCESS);
          }
        } catch (e) {
          setErrorMessage(String(e));
          setDisplayStatus(DISPLAY.ERROR);
        }
      }
    };
    fetchData();
  }, [authenticationState]);

  const isLoading =
    authenticationState === DISPLAY.LOADING ||
    displayStatus === DISPLAY.LOADING;
  const errorOccurred =
    authenticationState === DISPLAY.NOT_AUTH || displayStatus === DISPLAY.ERROR;
  const isSucess = displayStatus === DISPLAY.SUCCESS;

  return (
    <Router>
      {' '}
      <div className='App'>
        <HandleLogin setAuthenticationState={setAuthenticationState} />
        {isLoading && (
          <Container className='loadingScreen'>
            <Spinner animation='grow' />
            <p>Loading</p>
          </Container>
        )}
        {errorOccurred && <ErrorContainer message={errorMessage} />}
        {isSucess && (
          <div>
            <Header />
            <NavigationTab
              selectedView={selectedView}
              setSelectedView={setSelectedView}
            />
            <Routes>
              <Route
                path='OutcomeReport/*'
                element={
                  <div className={'finalOutcomeContainer m-3'}>
                    <FinalOutcomeReport userData={userDetails} />
                  </div>
                }
              />
              <Route
                path={'Report/*'}
                element={
                  <div>
                    <PowerBIEmbedded userDataList={userDetails} />
                  </div>
                }
              />
              <Route path={'/'} element={<Navigate replace to='/Report' />} />
            </Routes>
          </div>
        )}
      </div>
    </Router>
  );
};

export default App;
