import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import FilterSection from '../FilterSection/FilterSection';
import ActivityLogModal from '../ActivityLogModal/ActivityLogModal';
import styles from './FinalOutcomeReport.module.css';
import ActivityLogIcon from '../../images/FinalOutcomeReportImages/Activity Log.png';
import {
  UserDetailsObject,
  FinalOutcomeReportProps,
  CurrentFilterSelection,
  ActivityLogItem,
  ReportLineItemObject,
  ReportCommentObject,
  ReportDetailsObject,
  EmailRecipientAndGmCfoDcCountObject,
  EmailNotificationObject,
} from '../interfaces';
import {
  DISPLAY,
  getUserRoleCode,
  getReportStatusCode,
  getUserRoleString,
  OUTCOME,
  USERROLECODE,
  getReportStatusString,
  ERRORS,
  REPORTSTATUSCODE,
  getReportStatusAfterSubmit,
  GMCFOMAILINGFLAG,
  REPORTSTATUSSTRING,
  MAILINGTYPE,
  getMailSubjectAndBody,
  DEFAULT_EMAIL_GM_CFO_LIST,
  getReportingLevelCode,
  REPORTINGLEVElSTRING,
} from '../constants';
import ReportComment from '../ReportComment/ReportComment';
import ReportLineItemContainer from '../ReportLineItemContainer/ReportLineItemContainer';
import ReportSubmission from '../ReportSubmission/ReportSubmission';
import ReportDetails from '../ReportDetails/ReportDetails';
import ProgressStepper from '../ProgressStepper/ProgressStepper';
import {
  DeleteLineItems,
  getAcceptanceFlagForGmCfo,
  getActivityLogDetails,
  getMailListAndGmCfoCount,
  getReportComments,
  getReportDetails,
  getReportLineItems,
  insertActivityLog,
  InsertOrUpdateReportLineItems,
  sendMailToUsers,
  submissionForApprovers,
  updateReportDetails,
} from '../apiCalls';
import FinalSubmissionLevelInput from '../FinalSubmissionLevelInput/FinalSubmissionLevelInput';
import DefaultScreen from '../UtilContainer/DefaultScreen';
import DisabledScreen from '../UtilContainer/DisabledScreen';
import ErrorScreen from '../UtilContainer/ErrorScreen';
import ActivityLogComponent from '../ActivityLogComponent/ActivityLogComponent';
import TableView from '../ReportLineItemContainer/TableView';
import { Route, Routes } from 'react-router-dom';

const FinalOutcomeReport: React.FC<FinalOutcomeReportProps> = (
  props: FinalOutcomeReportProps
) => {
  const [displayStatus, setDisplayStatus] = useState<string>(DISPLAY.DEFAULT);
  const [showActivitylog, setActivityLogView] = useState<boolean>(false);
  const [activityLogLoading, setLoading] = useState<boolean>(false);
  const [selectedFilter, setFilter] = useState<CurrentFilterSelection>();
  const [activityLogs, setActivityLogs] = useState<ActivityLogItem[]>([]);
  const [userRole, setUserRole] = useState<number>(100);
  const [reportStatus, setReportStatus] = useState<number>(100);
  const [reportDetails, setReportDetails] = useState<ReportDetailsObject>();
  const [reportLineItems, setReportLineItems] = useState<
    ReportLineItemObject[]
  >([]);
  const [deletedLineItemList, setDeletedLineItemList] = useState<number[]>([]);
  const [reportComments, setReportComments] = useState<ReportCommentObject[]>(
    []
  );
  const [reportEditMode, setReportEditMode] = useState<boolean>(false);
  const [reportSubmissionMode, setReportSubmissionMode] = useState<boolean>(
    false
  );
  const [validationFlag, setValidationFlag] = useState<boolean>(false);
  const [unsavedItemCounter, setUnsavedItemCounter] = useState<number>(0);
  const [alertMessage, setAlertMessage] = useState<string>(
    ERRORS.INVALID_SAVE_ALERT
  );
  const [
    emailListAndGmCfoDcCount,
    setemailListAndGmCfoDcCount,
  ] = useState<EmailRecipientAndGmCfoDcCountObject>(DEFAULT_EMAIL_GM_CFO_LIST);

  const fetchData = useCallback(async () => {
    if (selectedFilter) {
      setDisplayStatus(DISPLAY.LOADING);

      try {
        const responseReport = await getReportDetails(
          selectedFilter.yearQuarter,
          getReportingLevelCode(selectedFilter.reportingLevel),
          selectedFilter.unitCode
        );
        const temporaryState = getReportStatusCode(responseReport.state);
        setReportStatus(temporaryState);
        const possibleRole: number[] = props.userData
          .filter((item: UserDetailsObject) => {
            switch (selectedFilter.reportingLevel) {
              case REPORTINGLEVElSTRING.SECTOR:
                return item.sectorName === selectedFilter.unitCode;
              case REPORTINGLEVElSTRING.BU:
                return item.buName === selectedFilter.unitCode;
              case REPORTINGLEVElSTRING.MARKET:
                return item.mktUnitName === selectedFilter.unitCode;
              default:
                return item.compCode === selectedFilter.unitCode;
            }
          })
          .map((i: UserDetailsObject) => getUserRoleCode(i.role));

        let temporaryUserRole;
        if (possibleRole.includes(temporaryState)) {
          temporaryUserRole = temporaryState;
        } else {
          temporaryUserRole = Math.max(...possibleRole);
        }
        setUserRole(temporaryUserRole);

        let temporaryEditMode = false;
        let temporarySubmissionMode = false;

        // access validation
        if (
          temporaryUserRole > temporaryState &&
          !(
            temporaryUserRole === USERROLECODE.VIEWER &&
            temporaryState >= REPORTSTATUSCODE.APPROVED
          ) &&
          !(
            temporaryUserRole === USERROLECODE.REVIEWER &&
            temporaryState === REPORTSTATUSCODE.NOTPREPARED
          )
        ) {
          setDisplayStatus(DISPLAY.DISABLED);
        } else {
          if (
            temporaryUserRole === USERROLECODE.CONTROLLER &&
            temporaryState === REPORTSTATUSCODE.ACCEPTED
          ) {
            temporaryEditMode = false;
            temporarySubmissionMode = true;
          } else if (
            temporaryUserRole === USERROLECODE.GM_CFO &&
            temporaryState === REPORTSTATUSCODE.APPROVED
          ) {
            temporaryEditMode = false;
            temporarySubmissionMode = true;
          } else if (
            temporaryUserRole === USERROLECODE.GM_CFO &&
            temporaryState === REPORTSTATUSCODE.INTER_ACCEPTED
          ) {
            temporaryEditMode = false;
            const acceptanceFlag = await getAcceptanceFlagForGmCfo(
              selectedFilter.yearQuarter,
              selectedFilter.unitCode,
              sessionStorage.getItem('userEmail') || '' // props.userData[0].emailId,
            );

            temporarySubmissionMode = acceptanceFlag === 1 ? false : true;
          } else if (
            temporaryUserRole === temporaryState &&
            temporaryUserRole !== USERROLECODE.VIEWER
          ) {
            temporaryEditMode = true;
            temporarySubmissionMode = true;
          } else {
            temporaryEditMode = false;
            temporarySubmissionMode = false;
          }
          setReportSubmissionMode(temporarySubmissionMode);
          setReportEditMode(temporaryEditMode);
          setReportDetails(responseReport);

          const responseLineItems = await getReportLineItems(
            selectedFilter.yearQuarter,
            selectedFilter.unitCode
          );
          setReportLineItems(responseLineItems);

          if (temporaryEditMode) {
            const responseReportComments = await getReportComments(
              selectedFilter.yearQuarter,
              selectedFilter.unitCode,
              getUserRoleString(temporaryUserRole)
            );
            setReportComments(responseReportComments);
          }

          if (temporarySubmissionMode) {
            // call teradata sp for to and cc list and gm cfo count
            const responseEmailList = await getMailListAndGmCfoCount(
              getReportingLevelCode(selectedFilter.reportingLevel),
              selectedFilter.unitCode,
              getUserRoleString(temporaryUserRole),
              responseReport.finalSubmissionLevel
            );
            setemailListAndGmCfoDcCount(responseEmailList);
          }

          if (temporaryState === REPORTSTATUSCODE.SUBMITTED) {
            const responseActivityLogs = await getActivityLogDetails(
              selectedFilter.yearQuarter,
              selectedFilter.unitCode
            );
            setActivityLogs(responseActivityLogs);
          }

          setDisplayStatus(DISPLAY.SUCCESS);
        }
      } catch (e) {
        console.log(e);
        setDisplayStatus(DISPLAY.ERROR);
      }
    }
  }, [props.userData, selectedFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData, props.userData, selectedFilter]);

  const displayLogs = async () => {
    try {
      setLoading(true);
      if (selectedFilter) {
        const response = await getActivityLogDetails(
          selectedFilter.yearQuarter,
          selectedFilter.unitCode
        );
        setLoading(false);
        setActivityLogView(true);
        setActivityLogs(response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const validateData = (actionType: string) => {
    if (actionType === OUTCOME.SAVE && unsavedItemCounter !== 0) {
      setAlertMessage(ERRORS.INVALID_SAVE_ALERT);
      window.scrollTo(0, 0);
      setValidationFlag(true);
      return 0;
    } else if (
      actionType === OUTCOME.APPROVE ||
      actionType === OUTCOME.REJECT
    ) {
      const emptyLineItem = reportLineItems.filter(
        (lineItem: ReportLineItemObject) => {
          return (
            lineItem.bsrObservation === '' ||
            lineItem.financialImpact === '' ||
            lineItem.schedule === '' ||
            lineItem.reviewerPeriod === ''
          );
        }
      );
      if (
        reportDetails?.summary === '' ||
        reportDetails?.dateOfReview === '' ||
        reportDetails?.reviewPeriod === '' ||
        emptyLineItem.length !== 0 ||
        unsavedItemCounter !== 0
      ) {
        setAlertMessage(ERRORS.INVALID_SUBMIT_ALERT);
        window.scrollTo(0, 0);
        setValidationFlag(true);
        return 0;
      }
    }
    return 1;
  };

  const saveUpdates = async () => {
    setDisplayStatus(DISPLAY.LOADING);
    try {
      if (selectedFilter && reportDetails) {
        await updateReportDetails({
          yearQuarter: selectedFilter.yearQuarter,
          reportingLevel: getReportingLevelCode(selectedFilter.reportingLevel),
          unitCode: selectedFilter.unitCode,
          state: getReportStatusString(reportStatus),
          summary: reportDetails.summary,
          reviewPeriod: reportDetails.reviewPeriod,
          dateOfReview: reportDetails.dateOfReview,
          attendees: reportDetails.attendees,
          finalSubmissionLevel: reportDetails.finalSubmissionLevel,
        });
        if (reportLineItems.length > 0) {
          await InsertOrUpdateReportLineItems(reportLineItems);
        }
        if (deletedLineItemList.length > 0) {
          await DeleteLineItems(deletedLineItemList);
          setDeletedLineItemList([]);
        }
      }
      setValidationFlag(false);
      await fetchData();
    } catch {
      setDisplayStatus(DISPLAY.ERROR);
    }
  };

  const submitUpdates = async (
    outcomeStatus: string,
    comment: string,
    toMailList: string,
    ccMailList: string
  ) => {
    setDisplayStatus(DISPLAY.LOADING);
    let statusAfterApproval = REPORTSTATUSSTRING.NOTPREPARED;

    // updating to mail list
    toMailList =
      toMailList === ''
        ? sessionStorage.getItem('userEmail') || ''
        : toMailList;

    toMailList = toMailList
      .replaceAll(',', ';')
      .replaceAll(' ', '')
      .replaceAll('\n', '');
    ccMailList = ccMailList
      .replaceAll(',', ';')
      .replaceAll(' ', '')
      .replaceAll('\n', '');

    if (selectedFilter && reportDetails && emailListAndGmCfoDcCount) {
      try {
        if (
          userRole === USERROLECODE.GM_CFO ||
          userRole === USERROLECODE.CONTROLLER
        ) {
          const responseMailingFlag = await submissionForApprovers({
            yearQuarter: selectedFilter.yearQuarter,
            reportingLevel: getReportingLevelCode(
              selectedFilter.reportingLevel
            ),
            unitCode: selectedFilter.unitCode,
            userRole: getUserRoleString(userRole),
            userEmail: sessionStorage.getItem('userEmail') || '',
            finalSubmissionLevel: reportDetails.finalSubmissionLevel,
            totalGMCFOCount: emailListAndGmCfoDcCount.gmCfoCount,
            outcome: outcomeStatus,
            comment: comment,
          });

          switch (responseMailingFlag) {
            case GMCFOMAILINGFLAG.SEND_SUBMITTED_MAIL:
              statusAfterApproval = REPORTSTATUSSTRING.SUBMITTED;
              break;
            case GMCFOMAILINGFLAG.SEND_ACCEPTED_MAIL:
              statusAfterApproval = REPORTSTATUSSTRING.ACCEPTED;
              break;
            case GMCFOMAILINGFLAG.SEND_IN_PROGRESS_MAIL:
              statusAfterApproval =
                outcomeStatus === OUTCOME.APPROVE
                  ? REPORTSTATUSSTRING.INTER_ACCEPTED
                  : REPORTSTATUSSTRING.REVIEWED;
              break;
          }
        } else {
          statusAfterApproval =
            outcomeStatus === OUTCOME.APPROVE
              ? getReportStatusAfterSubmit(
                  userRole,
                  reportStatus,
                  reportDetails.finalSubmissionLevel
                )
              : getReportStatusString(reportStatus - 1);

          if (reportLineItems.length > 0) {
            await InsertOrUpdateReportLineItems(reportLineItems);
          }
          if (deletedLineItemList.length > 0) {
            await DeleteLineItems(deletedLineItemList);
            setDeletedLineItemList([]);
          }
          await insertActivityLog({
            yearQuarter: selectedFilter.yearQuarter,
            reportingLevel: getReportingLevelCode(
              selectedFilter.reportingLevel
            ),
            unitCode: selectedFilter.unitCode,
            userEmail: sessionStorage.getItem('userEmail') || '', // props.userData[0].emailId,
            userRole: getUserRoleString(userRole),
            status:
              outcomeStatus === OUTCOME.APPROVE
                ? statusAfterApproval
                : 'Rejected',
            comment: comment,
            commentFor:
              outcomeStatus === OUTCOME.APPROVE
                ? getUserRoleString(getReportStatusCode(statusAfterApproval))
                : getUserRoleString(userRole - 1),
            timeStamp: '',
            id: 0,
          });

          await updateReportDetails({
            yearQuarter: selectedFilter.yearQuarter,
            reportingLevel: getReportingLevelCode(
              selectedFilter.reportingLevel
            ),
            unitCode: selectedFilter.unitCode,
            state:
              outcomeStatus === OUTCOME.APPROVE
                ? statusAfterApproval
                : getReportStatusString(reportStatus - 1),
            summary: reportDetails.summary,
            reviewPeriod: reportDetails.reviewPeriod,
            dateOfReview: reportDetails.dateOfReview,
            attendees: reportDetails.attendees,
            finalSubmissionLevel: reportDetails.finalSubmissionLevel,
          });
        }

        // sending final mail
        const emailObject: EmailNotificationObject = {
          initiatorID: sessionStorage.getItem('userEmail') || '',
          initiatorRole: getUserRoleString(userRole),
          subject: '',
          body: '',
          toList: toMailList,
          ccList: ccMailList,
          mailSignature: '',
        };

        // updating for GM CGO
        if (outcomeStatus === OUTCOME.APPROVE) {
          if (
            userRole === USERROLECODE.GM_CFO &&
            statusAfterApproval !== REPORTSTATUSSTRING.INTER_ACCEPTED
          ) {
            emailObject.toList = emailListAndGmCfoDcCount.dcListForGmCfo;
          }
        }

        // setting up mail type
        let mailType: number = MAILINGTYPE.APPROVAL_MAIL;
        if (outcomeStatus === OUTCOME.REJECT) {
          mailType = MAILINGTYPE.REJECTION_MAIL;
        } else if (statusAfterApproval === REPORTSTATUSSTRING.SUBMITTED) {
          mailType = MAILINGTYPE.FINAL_SUBMISSION_MAIL;
        }

        [
          emailObject.subject,
          emailObject.body,
          emailObject.mailSignature,
        ] = getMailSubjectAndBody(
          mailType,
          selectedFilter.reportingLevel,
          selectedFilter.unitCode,
          selectedFilter.yearQuarter,
          statusAfterApproval,
          sessionStorage.getItem('userEmail') || '',
          getUserRoleString(userRole),
          comment
        );
        await sendMailToUsers(emailObject);
        setValidationFlag(false);
        await fetchData();
      } catch (e) {
        console.log(e);
      }
    }
  };
  const isLoading = displayStatus === DISPLAY.LOADING;
  const isSuccess = displayStatus === DISPLAY.SUCCESS;
  const isDefault = displayStatus === DISPLAY.DEFAULT;
  const isDisabled = displayStatus === DISPLAY.DISABLED;
  const isError = displayStatus === DISPLAY.ERROR;
  return (
    <>
      <Alert
        variant='danger'
        show={validationFlag}
        className='m-2'
        onClose={() => setValidationFlag(false)}
        transition={false}
        dismissible
      >
        {alertMessage}
      </Alert>
      <Container fluid className='ml-auto mt-2'>
        <Row>
          <Col>
            <h3>BSR Workflow</h3>
          </Col>
          {isSuccess && (
            <Col className={styles.activityLogLink}>
              <span className={styles.logOption} onClick={displayLogs}>
                <img
                  className={styles.activityLogIcon}
                  src={ActivityLogIcon}
                  alt='Activity Log'
                />
                <span className='ml-2'>Activity Log</span>
                {activityLogLoading && (
                  <Spinner className='ml-2' animation='border' size='sm' />
                )}
              </span>
            </Col>
          )}
        </Row>
      </Container>
      <Routes>
        <Route
          path=':year/:quarter/:reportingLevel/:unitCode'
          element={
            <FilterSection
              unitCodeInformation={props.userData.map(
                (obj: UserDetailsObject) => {
                  return {
                    Sector: obj.sectorName,
                    BU: obj.buName,
                    Market: obj.mktUnitName,
                    CompanyName: obj.compName,
                    CompCode: obj.compCode,
                  };
                }
              )}
              setCurrentFilters={setFilter}
            />
          }
        />
        <Route
          path=''
          element={
            <FilterSection
              unitCodeInformation={props.userData.map(
                (obj: UserDetailsObject) => {
                  return {
                    Sector: obj.sectorName,
                    BU: obj.buName,
                    Market: obj.mktUnitName,
                    CompanyName: obj.compName,
                    CompCode: obj.compCode,
                  };
                }
              )}
              setCurrentFilters={setFilter}
            />
          }
        />
      </Routes>

      {userRole !== REPORTSTATUSCODE.DEFAULT && (
        <ProgressStepper
          currentReportStatus={reportStatus}
          currentRole={userRole}
        />
      )}
      {isLoading && (
        <Container className={styles.loadingScreen}>
          <Spinner animation='grow' />
          <p>Loading</p>
        </Container>
      )}
      {isSuccess && (
        <div>
          {selectedFilter && reportDetails && (
            <>
              <ReportDetails
                reportDetails={reportDetails}
                reportEditMode={reportEditMode}
                selectedFilter={selectedFilter}
                setDetails={setReportDetails}
              />
              {reportEditMode && (
                <ReportLineItemContainer
                  selectedFilter={selectedFilter}
                  lineItems={reportLineItems}
                  setLineItems={setReportLineItems}
                  deletedLineItemList={deletedLineItemList}
                  setDeletedLineItemList={setDeletedLineItemList}
                  reportEditMode={reportEditMode}
                  unsavedItemCounter={unsavedItemCounter}
                  setUnsavedItemCounter={setUnsavedItemCounter}
                />
              )}
            </>
          )}
          {!reportEditMode && <TableView lineItems={reportLineItems} />}
          {reportEditMode && reportComments.length !== 0 && (
            <ReportComment comments={reportComments} />
          )}
          {userRole <= USERROLECODE.APPROVER && reportDetails && (
            <FinalSubmissionLevelInput
              reportDetails={reportDetails}
              setDetails={setReportDetails}
              reportEditMode={reportEditMode}
              gmCFONotPresent={
                emailListAndGmCfoDcCount.gmCfoCount > 0 ? false : true
              }
              dcNotPresent={emailListAndGmCfoDcCount.dcCount > 0 ? false : true}
            />
          )}
          {reportStatus !== REPORTSTATUSCODE.SUBMITTED &&
            userRole !== USERROLECODE.VIEWER &&
            emailListAndGmCfoDcCount && (
              <ReportSubmission
                handleValidate={validateData}
                handleSave={saveUpdates}
                handleSubmit={submitUpdates}
                reportEditMode={reportSubmissionMode}
                userRole={userRole}
                emailList={emailListAndGmCfoDcCount}
              />
            )}
          {reportStatus === REPORTSTATUSCODE.SUBMITTED && (
            <>
              <ActivityLogComponent logs={activityLogs} />
            </>
          )}
        </div>
      )}
      {isDefault && <DefaultScreen />}
      {isDisabled && <DisabledScreen />}
      {isError && <ErrorScreen />}
      {selectedFilter && (
        <ActivityLogModal
          currentFilter={selectedFilter}
          logs={activityLogs}
          showState={showActivitylog}
          setActivityView={() => setActivityLogView(false)}
        />
      )}
    </>
  );
};
export default FinalOutcomeReport;
