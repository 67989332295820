import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { ActivityLogComponentProps } from '../interfaces';
import styles from './ActivityLogComponent.module.css';

// Grid displaying details for each Activity Log item
const ActivityLogComponent: React.FC<ActivityLogComponentProps> = (
  props: ActivityLogComponentProps
) => {
  /* 
  Description: Function to convert system (UTC) time to User's local time
  Input: UTC time 
  Output: User's local time
  */
  const getLocalTimeStamp = (dateString: string): string => {
    const localdate = new Date(dateString);
    return localdate.toLocaleString();
  };

  return (
    <Container fluid={true} className={styles.tableContainer + ' mt-2'}>
      <label className={styles.inputLabels}>Activity Timeline</label>
      <Table striped bordered hover size='sm' className='ml-auto mt-1'>
        <thead>
          <tr>
            <th>User</th>
            <th>Role</th>
            <th>Status</th>
            <th>Comment</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {props.logs.map((item) => {
            return (
              <tr key={item.id}>
                <td>{item.userEmail}</td>
                <td>{item.userRole}</td>
                <td>{item.status}</td>
                <td>{item.comment}</td>
                <td className={styles.dateValues}>
                  {getLocalTimeStamp(item.timeStamp)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default ActivityLogComponent;
